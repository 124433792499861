import * as pages from '@/pages'

export const routes = {
    NOT_FOUND: {
        path: '*',
        element: <pages.NotFound />
    },
    LOGIN: {
        path: '/login',
        element: <pages.Login />
    },
    FORGOT_PASSWORD: {
        path: '/forgot-password',
        element: <pages.ForgotPassword />
    },
    CHANGE_PASSWORD: {
        path: '/change-password',
        element: <pages.ChangePassword />
    },
    DASHBOARD: {
        path: '/',
        element: <pages.Dashboard />
    },
    ORIENTATION: {
        path: '/orientation',
        element: <pages.ContentsContainer />
    },
    CHARACTERS: {
        path: '/characters',
        element: <pages.ContentsContainer />
    },
    HISTORY: {
        path: '/history',
        element: <pages.ContentsContainer />
    },
    SPACES: {
        path: '/spaces',
        element: <pages.ContentsContainer />
    },
    BIBLIOGRAPHIC_FUNDS: {
        path: '/bibliographic-funds',
        element: <pages.ContentsContainer />
    },
    NUMISMATICS: {
        path: '/numismatics',
        element: <pages.ContentsContainer />
    },
    ARCHEOLOGY: {
        path: '/archeology',
        element: <pages.ContentsContainer />
    },
    SCIENTIFIC_INSTRUMENTS: {
        path: '/scientific-instruments',
        element: <pages.ContentsContainer />
    },
    PROFILE: {
        path: '/profile',
        element: <pages.Profile />
    },
    USERS: {
        path: '/users',
        element: <pages.Users />
    },
    ORIENTATION_DETAIL: {
        path: '/orientation/:name',
        element: <pages.ContentDetail />
    },
    CHARACTERS_DETAIL: {
        path: '/characters/:name',
        element: <pages.ContentDetail />
    },
    HISTORY_DETAIL: {
        path: '/history/:name',
        element: <pages.ContentDetail />
    },
    SPACES_DETAIL: {
        path: '/spaces/:name',
        element: <pages.ContentDetail />
    },
    BIBLIOGRAPHIC_FUNDS_DETAIL: {
        path: '/bibliographic-funds/:name',
        element: <pages.ContentDetail />
    },
    NUMISMATICS_DETAIL: {
        path: '/numismatics/:name',
        element: <pages.ContentDetail />
    },
    ARCHEOLOGY_DETAIL: {
        path: '/archeology/:name',
        element: <pages.ContentDetail />
    },
    SCIENTIFIC_INSTRUMENTS_DETAIL: {
        path: '/scientific-instruments/:name',
        element: <pages.ContentDetail />
    },
    USER_DETAIL: {
        path: '/users/:name',
        element: <pages.Profile />
    }
}
