import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {useForm, useFieldArray, Controller, FieldArrayWithId} from 'react-hook-form'
import {Button} from '@/components/ui/button/Button'
import {
    AnimatedCard,
    ContentCardContainer,
    ContentInputContainer,
    StyledForm,
    StyledFormItemContainer,
    StyledFormItemsContainer,
    StyledScrollForm,
    StyledScrollRightForm,
    StyledXcloseIcon
} from './style'
import {ArrowDownIcon, ArrowUpIcon} from '@/components/ui/icon/Icon'
import ContentAddIcon from '@assets/images/content_add_icon.svg'
import {InputText} from '@/components/commons/input-text/InputText'
import {TextArea} from '@/components/commons/textarea/TextArea'
import {Select, SelectValue} from '@/components/commons/select/Select'
import {zodResolver} from '@hookform/resolvers/zod'
import {InputFile} from '@/components/commons/input-file/InputFile'
import {ContentResponse, ContentsResponse} from '@/features/contents/ContentsSchema'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {containersRemap, remapDeliverySystems, scrollToBlock, findContentBlockTitles} from '@/utilities/helpers'
import {LangType, containerOptions, isVisibleOptions, visibleOnOptions} from '@/utilities/constants/commons'
import {useMutation} from '@tanstack/react-query'
import {createContentDetail, createIfIdExistContentDetail, updateContentDetail} from '@/features/contents/contents'
import toast from 'react-hot-toast'
import {errorHandler} from '@/utilities/genericErrorHandler'
import {useLocation} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {ContentFormSchema, ContentFormType, PayloadFormType} from '@/features/contents/ContentFormSchema'
import {useContentFormStore} from '@/utilities/useContentFormStore'

interface ContentFormProps {
    detail?: ContentResponse
    data: ContentsResponse
    lang: LangType
    hasChanged: boolean
    setHasChanged: Dispatch<SetStateAction<boolean>>
}

export const ContentForm = ({detail, lang, data, hasChanged, setHasChanged}: ContentFormProps) => {
    const {t} = useTranslation()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const containerId = searchParams.get('containerId')
    // const id = searchParams.get('id')
    const {formData, setFormData, resetFormData} = useContentFormStore()
    const [swipedIndex, setSwipedIndex] = useState<number | undefined>(undefined)
    const [deleteIds, setDeleteIds] = useState<number[]>([])
    const {
        control,
        handleSubmit,
        register,
        watch,
        getValues,
        reset,
        setValue,
        formState: {errors, touchedFields, dirtyFields}
    } = useForm<ContentFormType>({
        resolver: zodResolver(ContentFormSchema),
        mode: 'onBlur',
        defaultValues: {
            title: formData[lang]?.title || detail?.contentData?.find(i => i.language === lang)?.title,
            imagePath: formData[lang]?.imagePath || detail?.imagePath,
            isVisible:
                formData[lang]?.isVisible ||
                isVisibleOptions.find(i => i.value === detail?.isVisible.toString()) ||
                isVisibleOptions[0],
            container:
                formData[lang]?.container ||
                containerOptions.find(i => +i.value === detail?.containerId) ||
                containerOptions.find(i => i.value === containerId),
            deliverySystems: remapDeliverySystems(detail?.deliverySystems) || visibleOnOptions,
            referencesCatalogs: findContentBlockTitles(1, data, detail?.recommendations),
            referencesApp: findContentBlockTitles(2, data, detail?.recommendations),
            referencesMuseal: findContentBlockTitles(3, data, detail?.recommendations),
            referencesMonumental: findContentBlockTitles(4, data, detail?.recommendations),
            contentBlocks: detail?.contentBlocks?.filter(i => i.language === lang)?.length
                ? detail?.contentBlocks
                      ?.filter(i => i.language === lang)
                      .map(item => ({
                          id: item?.id || undefined,
                          title: item?.title || undefined,
                          text: item?.text || undefined,
                          language: item?.language || undefined,
                          imagePath: item.imagePath || new File([], '')
                      }))
                : [
                      {
                          id: undefined,
                          title: undefined,
                          text: undefined,
                          language: lang,
                          imagePath: undefined
                      }
                  ],
            firstLink: detail?.firstLink || undefined,
            secondLink: detail?.secondLink || undefined,
            thirdLink: detail?.thirdLink || undefined
        }
    })

    useEffect(() => {
        if (!detail) {
            reset({
                title: '',
                imagePath: '',
                isVisible: isVisibleOptions[0],
                container: containerOptions.find(i => i.value === containerId) || undefined,
                deliverySystems: [],
                contentBlocks: [
                    {
                        title: '',
                        text: '',
                        language: lang,
                        imagePath: undefined
                    }
                ],
                firstLink: '',
                secondLink: '',
                thirdLink: ''
            })
            resetFormData()
        }
    }, [detail, reset, containerId, resetFormData, lang])
    const [prevLocation, setPrevLocation] = useState(location.pathname)
    useEffect(() => {
        if (prevLocation !== location.pathname) {
            resetFormData()
        }
        setPrevLocation(location.pathname)
    }, [location.pathname, prevLocation])

    useEffect(() => {
        if (detail && data) {
            setValue('referencesCatalogs', findContentBlockTitles(1, data, detail?.recommendations) || undefined)
            setValue('referencesApp', findContentBlockTitles(2, data, detail?.recommendations) || undefined)
            setValue('referencesMuseal', findContentBlockTitles(3, data, detail?.recommendations) || undefined)
            setValue('referencesMonumental', findContentBlockTitles(4, data, detail?.recommendations) || undefined)
        }
    }, [detail, data, setValue])

    const contentValidator = ['container', 'imagePath', 'firstLink', 'secondLink', 'thirdLink', 'isVisible'].some(
        (field: string) => Object.keys(dirtyFields).includes(field)
    )

    const titleValidator = ['title'].some((field: string) => Object.keys(dirtyFields).includes(field))

    const contentBlocksValidator = ['contentBlocks'].some((field: string) => Object.keys(dirtyFields).includes(field))

    const deliverySystemsValidator = ['deliverySystems'].some((field: string) =>
        Object.keys(dirtyFields).includes(field)
    )

    const referencesCatalogsValidator = ['referencesCatalogs'].some((field: string) =>
        Object.keys(dirtyFields).includes(field)
    )

    const referencesAppValidator = ['referencesApp'].some((field: string) => Object.keys(dirtyFields).includes(field))

    const referencesMusealValidator = ['referencesMuseal'].some((field: string) =>
        Object.keys(dirtyFields).includes(field)
    )

    const dreferencesMonumentalValidator = ['referencesMonumental'].some((field: string) =>
        Object.keys(dirtyFields).includes(field)
    )

    const createMutation = useMutation({
        retry: false,
        mutationKey: ['create-content'],
        mutationFn: (payload: ContentFormType) =>
            createContentDetail(
                'regular',
                payload,
                containerId,
                deliverySystemsValidator,
                referencesCatalogsValidator,
                referencesAppValidator,
                referencesMusealValidator,
                dreferencesMonumentalValidator
            ),
        onSuccess: data => {
            toast.success('Contenuto creato con successo')
            if (containerId && data?.contentId) {
                window.location.replace(`/${containersRemap(containerId)}?id=${containerId}`)
            }
        },
        onError: e => {
            errorHandler(e)
        }
    })

    const createIfIdExistMutation = useMutation({
        retry: false,
        mutationKey: ['create-content-exist'],
        mutationFn: (payload: ContentFormType) =>
            createIfIdExistContentDetail('regular', payload, detail?.id, {
                contentValidator,
                titleValidator,
                contentBlocksValidator,
                deliverySystemsValidator,
                referencesCatalogsValidator,
                referencesAppValidator,
                referencesMusealValidator,
                dreferencesMonumentalValidator
            }),
        onSuccess: data => {
            console.log(data)

            toast.success('Contenuto creato con successo')
            if (data) {
                if (containerId && data?.contentId) {
                    window.location.replace(`/${containersRemap(containerId)}?id=${containerId}`)
                }
            }
        },
        onError: e => {
            errorHandler(e)
        }
    })

    const currentContentDataId = detail?.contentData?.find(i => i.language === lang)?.id

    const updateMutation = useMutation({
        retry: false,
        mutationKey: ['udapte-content'],
        mutationFn: (payload: PayloadFormType) =>
            updateContentDetail('regular', payload, detail?.id, currentContentDataId, {
                contentValidator,
                titleValidator,
                contentBlocksValidator,
                deliverySystemsValidator,
                referencesCatalogsValidator,
                referencesAppValidator,
                referencesMusealValidator,
                dreferencesMonumentalValidator
            }),
        onSuccess: data => {
            toast.success('Contenuto aggiornato con successo')
            setDeleteIds([])
            if (data) {
                window.location.replace(`/${containersRemap(data.containerId)}?id=${containerId}`)
            }
        },
        onError: e => {
            errorHandler(e)
        }
    })

    const handleRemove = (index: number) => {
        const blockId = getValues(`contentBlocks.${index}.id`)

        if (blockId) {
            setDeleteIds(prev => [...prev, blockId])
        }
        remove(index)
    }
    const onSubmit = (payload: ContentFormType) => {
        const payloadWithDeletedIds = {
            ...payload,
            deleteIds
        }

        if (detail?.contentData?.find(i => i.language === lang)?.title) {
            updateMutation.mutate(payloadWithDeletedIds)
            setHasChanged(false)
        } else if (detail?.id) {
            createIfIdExistMutation.mutate(payloadWithDeletedIds)
            setHasChanged(false)
        } else {
            createMutation.mutate(payloadWithDeletedIds)
            setHasChanged(false)
        }
    }

    useEffect(() => {
        setHasChanged(true)
    }, [])

    // const { showWarning, confirmNavigation, cancelNavigation, handleBlockedNavigation } = useUnsavedChangesWarning(hasChanged);
    // console.log(showWarning);

    useEffect(() => {
        const subscription = watch(values => {
            setFormData(lang, values)
        })
        return () => {
            subscription.unsubscribe()
        }
    }, [watch, lang, setFormData])

    const {
        fields: blocks,
        append,
        swap,
        remove
    } = useFieldArray({
        control,
        name: 'contentBlocks'
    })

    const moveUp = (index: number) => {
        if (index > 0) {
            swap(index, index - 1)
            setTimeout(() => {
                setSwipedIndex(index - 1)
            }, 0)
        }
    }

    const moveDown = (index: number, fields: FieldArrayWithId<ContentFormType>[]) => {
        if (index < fields.length - 1) {
            swap(index, index + 1)
            setTimeout(() => {
                setSwipedIndex(index + 1)
                scrollToBlock(document.getElementById(`block-${index}`))
            }, 0)
        }
    }

    const onClickAppendRow = () => {
        append({
            id: undefined,
            title: undefined,
            text: undefined,
            language: lang,
            imagePath: null
        })
        setTimeout(() => {
            setSwipedIndex(blocks.length)
        }, 0)
    }

    return (
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <StyledFormItemsContainer>
                <StyledFormItemContainer>
                    <StyledScrollForm>
                        <ContentCardContainer>
                            <p className="input-title">{t('detail:cover')}</p>
                            <ContentInputContainer>
                                <p className="input-label">{t('detail:title')}</p>
                                <InputText
                                    {...register(`title`)}
                                    type="text"
                                    label={''}
                                    placeholder={'Titolo'}
                                    touched={touchedFields?.title}
                                    errorMessage={errors?.title?.message || ''}
                                    name={`title`}
                                    maxLength={30}
                                />
                            </ContentInputContainer>
                            <ContentInputContainer>
                                <p className="input-label">{t('detail:image')}</p>
                                <Controller
                                    name={`imagePath`}
                                    control={control}
                                    render={({field}) => (
                                        <InputFile
                                            {...register(`imagePath`)}
                                            touched={touchedFields?.imagePath}
                                            errorMessage={errors?.imagePath?.message || ''}
                                            type="file"
                                            acceptedFiles={{'image/*': ['.jpg', '.png']}}
                                            onRemoveFile={() => field.onChange(null)}
                                            maxSizeInMB={2}
                                            onAcceptFiles={e => field.onChange(e)}
                                            label=""
                                            imagePath={typeof field.value === 'string' ? field.value : null}
                                        />
                                    )}
                                />
                            </ContentInputContainer>
                        </ContentCardContainer>

                        {blocks.map((field, index) => (
                            <AnimatedCard key={field.id} $isAnimating={swipedIndex === index}>
                                {index > 0 && <StyledXcloseIcon onClick={() => handleRemove(index)} />}

                                <div className="label_container">
                                    <p className="input-title">
                                        {t('detail:block')} {index + 1}
                                    </p>
                                    <div className="card_arrows_container">
                                        {index > 0 && (
                                            <ArrowUpIcon onClick={() => moveUp(index)} width={20} className="arrow" />
                                        )}
                                        {index < blocks.length - 1 && (
                                            <ArrowDownIcon
                                                onClick={() => moveDown(index, blocks)}
                                                width={20}
                                                className="arrow"
                                            />
                                        )}
                                    </div>
                                </div>

                                <ContentInputContainer>
                                    <p className="input-label">{t('detail:title')}</p>
                                    <InputText
                                        {...register(`contentBlocks.${index}.title`)}
                                        type="text"
                                        label={''}
                                        placeholder={'Titolo'}
                                        touched={touchedFields?.contentBlocks?.[index]?.title}
                                        errorMessage={errors?.contentBlocks?.[index]?.title?.message || ''}
                                        name={`contentBlocks.${index}.title`}
                                        maxLength={75}
                                    />
                                </ContentInputContainer>
                                <ContentInputContainer>
                                    <p className="input-label">{t('detail:paragraph')}</p>
                                    <TextArea
                                        {...register(`contentBlocks.${index}.text`)}
                                        label={''}
                                        placeholder={'Paragrafo'}
                                        rows={6}
                                        maxLength={2500}
                                        touched={touchedFields?.contentBlocks?.[index]?.text}
                                        errorMessage={errors?.contentBlocks?.[index]?.text?.message || ''}
                                        name={`contentBlocks.${index}.text`}
                                    />
                                </ContentInputContainer>
                                <ContentInputContainer>
                                    <p className="input-label">{t('detail:image')}</p>
                                    <Controller
                                        name={`contentBlocks.${index}.imagePath`}
                                        control={control}
                                        render={({field}) => (
                                            <InputFile
                                                {...register(`contentBlocks.${index}.imagePath`)}
                                                touched={touchedFields?.contentBlocks?.[index]?.imagePath}
                                                errorMessage={errors?.contentBlocks?.[index]?.imagePath?.message || ''}
                                                type="file"
                                                acceptedFiles={{'image/*': ['.jpg', '.png']}}
                                                onRemoveFile={() => field.onChange(null)}
                                                maxSizeInMB={2}
                                                onAcceptFiles={e => field.onChange(e)}
                                                imagePath={typeof field.value === 'string' ? field.value : null}
                                            />
                                        )}
                                    />
                                </ContentInputContainer>
                                <div id={`block-${index}`} />
                            </AnimatedCard>
                        ))}
                        <Button variant="ghost" onClick={onClickAppendRow}>
                            <img src={ContentAddIcon} alt="Add icon" />
                        </Button>
                    </StyledScrollForm>
                </StyledFormItemContainer>
                <StyledFormItemContainer className="propsContainer">
                    <div className="ctaContainer">
                        <p className="title">Proprietà</p>

                        <Button type="submit" disabled={!hasChanged} style={{width: '94px'}}>
                            {!updateMutation.isPending ||
                            !createMutation.isPending ||
                            !createIfIdExistMutation.isPending ? (
                                `${detail?.contentData?.find(i => i.language === lang)?.title ? 'Aggiorna' : 'Crea'}`
                            ) : (
                                <Spinner size={16} />
                            )}
                        </Button>
                    </div>
                    <StyledScrollRightForm>
                        <Controller
                            render={({field: {onChange, value}}) => (
                                <Select
                                    {...register(`isVisible`)}
                                    value={value}
                                    onChange={newValue => {
                                        onChange(newValue as SelectValue[])
                                    }}
                                    size={'medium'}
                                    label={'Visibilità'}
                                    isClearable={true}
                                    isSearchable={false}
                                    errorMessage={errors?.isVisible?.message || ''}
                                    placeholder={'Seleziona'}
                                    options={isVisibleOptions}
                                    hideSelectedOptions={false}
                                />
                            )}
                            control={control}
                            name={'isVisible'}
                        />
                        <Controller
                            render={({field: {onChange, value}}) => (
                                <Select
                                    {...register(`container`)}
                                    value={value}
                                    onChange={newValue => {
                                        onChange(newValue as SelectValue[])
                                    }}
                                    size={'medium'}
                                    label={'Contenitore'}
                                    isClearable={true}
                                    isSearchable={false}
                                    errorMessage={errors?.container?.message || ''}
                                    placeholder={'Seleziona'}
                                    options={containerOptions}
                                    hideSelectedOptions={false}
                                    // disabled={!detail?.id}
                                />
                            )}
                            control={control}
                            name={'container'}
                        />

                        <Controller
                            defaultValue={[]}
                            render={({field: {onChange, value}}) => (
                                <Select
                                    {...register(`deliverySystems`)}
                                    value={value}
                                    onChange={newValue => {
                                        onChange(newValue as SelectValue[])
                                    }}
                                    size={'medium'}
                                    label={'Visibile su'}
                                    isMulti={true}
                                    isClearable={false}
                                    isSearchable={false}
                                    errorMessage={errors?.deliverySystems?.message || ''}
                                    placeholder={'Seleziona'}
                                    options={visibleOnOptions}
                                />
                            )}
                            control={control}
                            name={'deliverySystems'}
                        />

                        {/* {currentDelivertySystems?.find(i => i.value === '1') && (
                            <Controller
                                defaultValue={[]}
                                render={({field: {onChange, value}}) => (
                                    <Select
                                        {...register(`referencesCatalogs`)}
                                        value={value}
                                        onChange={newValue => {
                                            onChange(newValue as SelectValue[])
                                        }}
                                        size={'medium'}
                                        label={'Riferimenti collegati in Totem Sala Cataloghi'}
                                        isMulti={true}
                                        isClearable={false}
                                        isSearchable={true}
                                        errorMessage={errors?.referencesCatalogs?.message || ''}
                                        placeholder={'Seleziona'}
                                        options={remapContentsSystems(1, data)}
                                    />
                                )}
                                control={control}
                                name={'referencesCatalogs'}
                            />
                        )}
                        {currentDelivertySystems?.find(i => i.value === '2') && (
                            <Controller
                                defaultValue={[]}
                                render={({field: {onChange, value}}) => (
                                    <Select
                                        {...register(`referencesApp`)}
                                        value={value}
                                        onChange={newValue => {
                                            onChange(newValue as SelectValue[])
                                        }}
                                        size={'medium'}
                                        label={'Riferimenti collegati in Mobile App'}
                                        isMulti={true}
                                        isClearable={false}
                                        isSearchable={true}
                                        errorMessage={errors?.referencesApp?.message || ''}
                                        placeholder={'Seleziona'}
                                        options={remapContentsSystems(2, data)}
                                    />
                                )}
                                control={control}
                                name={'referencesApp'}
                            />
                        )}
                        {currentDelivertySystems?.find(i => i.value === '3') && (
                            <Controller
                                defaultValue={[]}
                                render={({field: {onChange, value}}) => (
                                    <Select
                                        {...register(`referencesMuseal`)}
                                        value={value}
                                        onChange={newValue => {
                                            onChange(newValue as SelectValue[])
                                        }}
                                        size={'medium'}
                                        label={'Riferimenti collegati in Totem Percorso Museale'}
                                        isMulti={true}
                                        isClearable={false}
                                        isSearchable={true}
                                        errorMessage={errors?.referencesMuseal?.message || ''}
                                        placeholder={'Seleziona'}
                                        options={remapContentsSystems(3, data)}
                                    />
                                )}
                                control={control}
                                name={'referencesMuseal'}
                            />
                        )}
                        {currentDelivertySystems?.find(i => i.value === '4') && (
                            <Controller
                                defaultValue={[]}
                                render={({field: {onChange, value}}) => (
                                    <Select
                                        {...register(`referencesMonumental`)}
                                        value={value}
                                        onChange={newValue => {
                                            onChange(newValue as SelectValue[])
                                        }}
                                        size={'medium'}
                                        label={'Riferimenti collegati in Totem Salone Monumentale'}
                                        isMulti={true}
                                        isClearable={false}
                                        isSearchable={true}
                                        errorMessage={errors?.referencesMonumental?.message || ''}
                                        placeholder={'Seleziona'}
                                        options={remapContentsSystems(4, data)}
                                    />
                                )}
                                control={control}
                                name={'referencesMonumental'}
                            />
                        )} */}

                        {/* <ContentLinkContainer>
                            <InputText
                                label={'Link Esterni'}
                                type={'text'}
                                touched={touchedFields.firstLink}
                                errorMessage={errors?.firstLink?.message || ''}
                                placeholder={'Aggiungi un link'}
                                {...register('firstLink')}
                                width={'320px'}
                                autoComplete={'off'}
                                typeIcon={<LinkIcon />}
                                name={'firstLink'}
                            />
                            <InputText
                                label={''}
                                type={'text'}
                                touched={touchedFields.secondLink}
                                errorMessage={errors?.secondLink?.message || ''}
                                placeholder={'Aggiungi un link'}
                                {...register('secondLink')}
                                width={'320px'}
                                autoComplete={'off'}
                                typeIcon={<LinkIcon />}
                                name={'secondLink'}
                            />
                            <InputText
                                label={''}
                                type={'text'}
                                touched={touchedFields.thirdLink}
                                errorMessage={errors?.thirdLink?.message || ''}
                                placeholder={'Aggiungi un link'}
                                {...register('thirdLink')}
                                width={'320px'}
                                autoComplete={'off'}
                                typeIcon={<LinkIcon />}
                                name={'thirdLink'}
                            />
                        </ContentLinkContainer> */}
                    </StyledScrollRightForm>
                </StyledFormItemContainer>
            </StyledFormItemsContainer>
        </StyledForm>
    )
}
