import axios from '@/axiosInstance.ts'
import toast from 'react-hot-toast'
import i18next from 'i18next'
import {DeliverySystemContentsSchema, DeliverySystemContentsType} from '../delivery/services/DeliverySchema'
import {ContentFormType, PayloadFormType} from './ContentFormSchema'
import {
    ContentsResponse,
    ContentsSchema,
    ContentResponse,
    ContentSchema,
    ContentReferencesType,
    ContentReferencesSchema
} from './ContentsSchema'

const handleImageUpload = async (file: File | string | null, type: 'container' | 'content' | 'contentBlock') => {
    if (!file) {
        return null
    }
    if (typeof file === 'string') {
        return file
    }

    if (file instanceof FileList && file.length === 0) {
        return null
    }

    const formData = new FormData()
    formData.append('image', file)
    formData.append('type', type)

    const response = await axios.post('/generics/upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })

    return response.data.imagePath
}

export const getContents = async (
    responseType: string,
    language: string,
    containerId: number,
    page: number,
    search: string
): Promise<ContentsResponse> => {
    const response = await axios.get(
        `/contents/?responseType=${responseType}&language=${language}&containerId=${containerId}&page=${page}&search=${search}&limit=20`
    )
    return ContentsSchema.parse(response.data)
}

export const getContentDetail = async (responseType: string, contentId?: number): Promise<ContentResponse> => {
    const response = await axios.get(`/contents/${contentId}/?responseType=${responseType}`)
    return ContentSchema.parse(response.data)
}

type RecommendationsType = {
    [key: number]: number[]
}

export const createContentDetail = async (
    responseType: string,
    payload: ContentFormType,
    containerId?: string | null,
    deliverySystemsValidator?: boolean,
    referencesCatalogsValidator?: boolean,
    referencesAppValidator?: boolean,
    referencesMusealValidator?: boolean,
    dreferencesMonumentalValidator?: boolean
) => {
    try {
        if (containerId) {
            let imagePath: string | null = null
            if (payload.imagePath && !(payload.imagePath instanceof FileList && payload.imagePath.length === 0)) {
                imagePath = await handleImageUpload(payload.imagePath, 'content')
            }

            const deliverySystems: number[] = payload.deliverySystems?.map(i => +i.value) || []

            let recommendations: RecommendationsType = {}

            if (deliverySystemsValidator) {
                recommendations = deliverySystems.reduce((acc, system) => {
                    acc[system] = []
                    return acc
                }, {} as RecommendationsType)

                const assignReferences = (
                    references: {value: string; label: string}[] | undefined,
                    deliverySystem: number
                ) => {
                    if (references) {
                        references.forEach(item => {
                            recommendations[deliverySystem].push(+item.value)
                        })
                    }
                }

                if (referencesCatalogsValidator) {
                    assignReferences(payload.referencesCatalogs, deliverySystems[0])
                }
                if (referencesAppValidator) {
                    assignReferences(payload.referencesApp, deliverySystems[1])
                }
                if (referencesMusealValidator) {
                    assignReferences(payload.referencesMuseal, deliverySystems[2])
                }
                if (dreferencesMonumentalValidator) {
                    assignReferences(payload.referencesMonumental, deliverySystems[3])
                }
            }

            type RequestDataType = {
                containerId: string | undefined
                imagePath: string | null
                firstLink: string | undefined
                secondLink: string | undefined
                thirdLink: string | undefined
                isVisible: number
                deliverySystems: number[]
                recommendations?: RecommendationsType
            }
            const requestData: RequestDataType = {
                containerId: payload.container?.value,
                imagePath: imagePath,
                firstLink: payload.firstLink,
                secondLink: payload.secondLink,
                thirdLink: payload.thirdLink,
                isVisible: +payload.isVisible.value,
                deliverySystems: deliverySystems,
                recommendations: recommendations
            }

            // if (
            //     deliverySystemsValidator &&
            //     (referencesCatalogsValidator ||
            //         referencesAppValidator ||
            //         referencesMusealValidator ||
            //         dreferencesMonumentalValidator)
            // ) {
            //     requestData.recommendations = recommendations
            // }

            const response = await axios.post(`/contents/?responseType=${responseType}`, requestData)

            const contentResponse = response?.data

            if (contentResponse) {
                // if (deliverySystemsValidator) {
                //     console.log(payload.deliverySystems)

                //     payload.deliverySystems?.forEach(async i => {
                //         await axios.post(`/delivery-system-contents/?responseType=${responseType}`, {
                //             contentId: contentResponse.id,
                //             deliverySystemId: +i.value
                //         })
                //     })
                // }

                const currentBlocks = await Promise.all(
                    payload?.contentBlocks.map(async (contentBlock, index) => ({
                        ...contentBlock,
                        contentId: contentResponse.id,
                        imagePath:
                            contentBlock.imagePath &&
                            !(contentBlock.imagePath instanceof File && contentBlock.imagePath.size === 0)
                                ? await handleImageUpload(contentBlock.imagePath, 'contentBlock')
                                : null,
                        index: index + 1
                    }))
                )

                if (currentBlocks) {
                    await axios.post(`/content-blocks/bulk/?responseType=${responseType}`, {
                        validatedData: currentBlocks
                    })
                }
                // if (referencesCatalogsValidator) {
                //     payload.referencesCatalogs?.forEach(async i => {
                //         await axios.post(`/recommendations/?responseType=${responseType}`, {
                //             parentContentId: contentResponse.id,
                //             relatedContentId: +i.value,
                //             deliverySystemId: 1
                //         })
                //     })
                // }
                // if (referencesAppValidator) {
                //     payload.referencesApp?.forEach(async i => {
                //         await axios.post(`/recommendations/?responseType=${responseType}`, {
                //             parentContentId: contentResponse.id,
                //             relatedContentId: +i.value,
                //             deliverySystemId: 2
                //         })
                //     })
                // }
                // if (referencesMusealValidator) {
                //     payload.referencesMuseal?.forEach(async i => {
                //         await axios.post(`/recommendations/?responseType=${responseType}`, {
                //             parentContentId: contentResponse.id,
                //             relatedContentId: +i.value,
                //             deliverySystemId: 3
                //         })
                //     })
                // }
                // if (dreferencesMonumentalValidator) {
                //     payload.referencesMonumental?.forEach(async i => {
                //         await axios.post(`/recommendations/?responseType=${responseType}`, {
                //             parentContentId: contentResponse.id,
                //             relatedContentId: +i.value,
                //             deliverySystemId: 4
                //         })
                //     })
                // }
                const contentData = await axios.post(`/content-data/?responseType=${responseType}`, {
                    contentId: contentResponse.id,
                    language: payload.contentBlocks[0].language,
                    title: payload.title
                })
                return contentData.data
            }
        }
    } catch (error) {
        toast.error(i18next.t('errors:default'))
    }
}

export const createIfIdExistContentDetail = async (
    responseType: string,
    payload: ContentFormType,
    contentId?: number,
    validator?: {
        contentValidator: boolean
        titleValidator: boolean
        contentBlocksValidator: boolean
        deliverySystemsValidator: boolean
        referencesCatalogsValidator: boolean
        referencesAppValidator: boolean
        referencesMusealValidator: boolean
        dreferencesMonumentalValidator: boolean
    }
) => {
    try {
        if (contentId) {
            if (validator?.contentBlocksValidator) {
                const imagePath = payload.imagePath ? await handleImageUpload(payload.imagePath, 'content') : null

                await axios.put(`/contents/${contentId}/?responseType=${responseType}`, {
                    containerId: payload.container?.value,
                    imagePath: imagePath,
                    firstLink: payload.firstLink,
                    secondLink: payload.secondLink,
                    thirdLink: payload.thirdLink,
                    isVisible: +payload.isVisible.value
                })
            }
            if (validator?.deliverySystemsValidator) {
                payload.deliverySystems?.forEach(async i => {
                    await axios.post(`/delivery-system-contents/?responseType=${responseType}`, {
                        contentId: contentId,
                        deliverySystemId: +i.value
                    })
                })
            }

            if (validator?.titleValidator) {
                await axios.post(`/content-data/?responseType=${responseType}`, {
                    contentId: contentId,
                    language: payload.contentBlocks[0].language,
                    title: payload.title
                })
            }
            if (validator?.referencesCatalogsValidator) {
                payload.referencesCatalogs?.forEach(async i => {
                    await axios.put(`/recommendations/?responseType=${responseType}`, {
                        parentContentId: contentId,
                        relatedContentId: +i.value,
                        deliverySystemId: 1
                    })
                })
            }
            if (validator?.referencesAppValidator) {
                payload.referencesApp?.forEach(async i => {
                    await axios.put(`/recommendations/?responseType=${responseType}`, {
                        parentContentId: contentId,
                        relatedContentId: +i.value,
                        deliverySystemId: 2
                    })
                })
            }
            if (validator?.referencesMusealValidator) {
                payload.referencesMuseal?.forEach(async i => {
                    await axios.put(`/recommendations/?responseType=${responseType}`, {
                        parentContentId: contentId,
                        relatedContentId: +i.value,
                        deliverySystemId: 3
                    })
                })
            }
            if (validator?.dreferencesMonumentalValidator) {
                payload.referencesMonumental?.forEach(async i => {
                    await axios.put(`/recommendations/?responseType=${responseType}`, {
                        parentContentId: contentId,
                        relatedContentId: +i.value,
                        deliverySystemId: 4
                    })
                })
            }

            if (validator?.contentBlocksValidator) {
                const currentBlocks = await Promise.all(
                    payload?.contentBlocks.map(async (contentBlock, index) => ({
                        ...contentBlock,
                        contentId: contentId,
                        imagePath:
                            contentBlock.imagePath &&
                            !(contentBlock.imagePath instanceof File && contentBlock.imagePath.size === 0)
                                ? await handleImageUpload(contentBlock.imagePath, 'contentBlock')
                                : null,
                        index: index + 1
                    }))
                )

                if (currentBlocks) {
                    await axios.post(`/content-blocks/bulk/?responseType=${responseType}`, {
                        validatedData: currentBlocks
                    })
                }
            }
        }
        return {title: payload.title, contentId: contentId, containerId: payload.container?.value}
    } catch (error) {
        toast.error(i18next.t('errors:default'))
    }
}

export const updateContentDetail = async (
    responseType: string,
    payload: PayloadFormType,
    contentId?: number,
    contentDataId?: number,
    validator?: {
        contentValidator: boolean
        titleValidator: boolean
        contentBlocksValidator: boolean
        deliverySystemsValidator: boolean
        referencesCatalogsValidator: boolean
        referencesAppValidator: boolean
        referencesMusealValidator: boolean
        dreferencesMonumentalValidator: boolean
    }
) => {
    try {
        console.log(payload)
        if (validator?.contentValidator) {
            let imagePath = null
            if (payload.imagePath && !(payload.imagePath instanceof FileList && payload.imagePath.length === 0)) {
                imagePath = await handleImageUpload(payload.imagePath, 'content')
            }
            await axios.put(`/contents/${contentId}/?responseType=${responseType}`, {
                containerId: payload.container?.value,
                imagePath: imagePath,
                firstLink: payload.firstLink,
                secondLink: payload.secondLink,
                thirdLink: payload.thirdLink,
                isVisible: +payload.isVisible.value
            })
        }

        let deliverySystems: number[] = []
        let recommendations: RecommendationsType = {}

        deliverySystems = payload.deliverySystems?.map(i => +i.value) || []

        recommendations = deliverySystems.reduce((acc, system) => {
            acc[system] = []
            return acc
        }, {} as RecommendationsType)

        const assignReferences = (references: {value: string; label: string}[] | undefined, deliverySystem: number) => {
            if (references) {
                references.forEach(item => {
                    recommendations[deliverySystem].push(+item.value)
                })
            }
        }

        if (validator?.referencesCatalogsValidator) {
            assignReferences(payload.referencesCatalogs, 1)
        }
        if (validator?.referencesAppValidator) {
            assignReferences(payload.referencesApp, 2)
        }
        if (validator?.referencesMusealValidator) {
            assignReferences(payload.referencesMuseal, 3)
        }
        if (validator?.dreferencesMonumentalValidator) {
            assignReferences(payload.referencesMonumental, 4)
        }

        deliverySystems.forEach(system => {
            if (recommendations[system].length === 0) {
                switch (system) {
                    case 1:
                        recommendations[system] = payload.referencesCatalogs?.map(ref => +ref.value) || []
                        break
                    case 2:
                        recommendations[system] = payload.referencesApp?.map(ref => +ref.value) || []
                        break
                    case 3:
                        recommendations[system] = payload.referencesMuseal?.map(ref => +ref.value) || []
                        break
                    case 4:
                        recommendations[system] = payload.referencesMonumental?.map(ref => +ref.value) || []
                        break
                    default:
                        break
                }
            }
        })

        await axios.put(`/contents/${contentId}/?responseType=${responseType}`, {
            containerId: payload.container?.value,
            firstLink: payload.firstLink,
            secondLink: payload.secondLink,
            thirdLink: payload.thirdLink,
            isVisible: +payload.isVisible.value,
            deliverySystems: deliverySystems,
            recommendations: recommendations
        })

        if (validator?.titleValidator) {
            await axios.put(`/content-data/${contentDataId}/?responseType=${responseType}`, {
                contentId: contentId,
                language: payload.contentBlocks[0].language,
                title: payload.title || null,
                imagePath: payload.imagePath || null
            })
        }

        if (validator?.contentBlocksValidator) {
            const currentBlocks = await Promise.all(
                payload?.contentBlocks.map(async (contentBlock, index) => ({
                    ...contentBlock,
                    contentId: contentId,
                    imagePath:
                        contentBlock.imagePath &&
                        !(contentBlock.imagePath instanceof File && contentBlock.imagePath.size === 0)
                            ? await handleImageUpload(contentBlock.imagePath, 'contentBlock')
                            : null,
                    index: index + 1
                }))
            )

            // Track IDs of deleted blocks
            const deleteIds = payload.deleteIds || []

            const blocksToUpdate = currentBlocks.filter(i => i.id !== undefined)
            const blocksToCreate = currentBlocks.filter(i => i.id === undefined)

            if (blocksToUpdate.length > 0 || deleteIds.length > 0) {
                await axios.put(`/content-blocks/bulk/?responseType=${responseType}`, {
                    validatedData: blocksToUpdate,
                    deleteIds: deleteIds // Include deleteIds in the payload
                })
            }
            if (blocksToCreate.length > 0) {
                await axios.post(`/content-blocks/bulk/?responseType=${responseType}`, {
                    validatedData: blocksToCreate
                })
            }
        }

        return {title: payload.title, contentId: contentId, containerId: payload.container?.value}
    } catch (error) {
        toast.error(i18next.t('errors:default'))
    }
}

// export const updateContentDetail = async (
//     responseType: string,
//     payload: ContentFormType,
//     contentId?: number,
//     contentDataId?: number,
//     validator?: {
//         contentValidator: boolean;
//         titleValidator: boolean;
//         contentBlocksValidator: boolean;
//         deliverySystemsValidator: boolean;
//         referencesCatalogsValidator: boolean;
//         referencesAppValidator: boolean;
//         referencesMusealValidator: boolean;
//         dreferencesMonumentalValidator: boolean;
//     }
// ) => {
//     try {
//         console.log(payload);

//         if (validator?.contentValidator) {
//             let imagePath: string | File | FileList | null = payload.imagePath;

//             // Handle image upload for content image
//             if (imagePath instanceof File) {
//                 imagePath = await handleImageUpload(imagePath, 'content');
//             } else if (imagePath instanceof FileList && imagePath.length > 0) {
//                 imagePath = await handleImageUpload(imagePath, 'content');
//             } else if (imagePath && typeof imagePath === 'object' && Object.keys(imagePath).length === 0) {
//                 // If it's an empty object, set it to null
//                 imagePath = null;
//             }

//             await axios.put(`/contents/${contentId}/?responseType=${responseType}`, {
//                 containerId: payload.container?.value,
//                 imagePath: imagePath,
//                 firstLink: payload.firstLink,
//                 secondLink: payload.secondLink,
//                 thirdLink: payload.thirdLink,
//                 isVisible: +payload.isVisible.value,
//             });
//         }

//         let deliverySystems: number[] = [];
//         let recommendations: RecommendationsType = {};

//         deliverySystems = payload.deliverySystems?.map(i => +i.value) || [];

//         recommendations = deliverySystems.reduce((acc, system) => {
//             acc[system] = [];
//             return acc;
//         }, {} as RecommendationsType);

//         const assignReferences = (
//             references: { value: string; label: string }[] | undefined,
//             deliverySystem: number
//         ) => {
//             if (references) {
//                 references.forEach((item) => {
//                     recommendations[deliverySystem].push(+item.value);
//                 });
//             }
//         };

//         if (validator?.referencesCatalogsValidator) {
//             assignReferences(payload.referencesCatalogs, 1);
//         }
//         if (validator?.referencesAppValidator) {
//             assignReferences(payload.referencesApp, 2);
//         }
//         if (validator?.referencesMusealValidator) {
//             assignReferences(payload.referencesMuseal, 3);
//         }
//         if (validator?.dreferencesMonumentalValidator) {
//             assignReferences(payload.referencesMonumental, 4);
//         }

//         deliverySystems.forEach((system) => {
//             if (recommendations[system].length === 0) {
//                 switch (system) {
//                     case 1:
//                         recommendations[system] =
//                             payload.referencesCatalogs?.map((ref) => +ref.value) || [];
//                         break;
//                     case 2:
//                         recommendations[system] =
//                             payload.referencesApp?.map((ref) => +ref.value) || [];
//                         break;
//                     case 3:
//                         recommendations[system] =
//                             payload.referencesMuseal?.map((ref) => +ref.value) || [];
//                         break;
//                     case 4:
//                         recommendations[system] =
//                             payload.referencesMonumental?.map((ref) => +ref.value) || [];
//                         break;
//                     default:
//                         break;
//                 }
//             }
//         });

//         await axios.put(`/contents/${contentId}/?responseType=${responseType}`, {
//             containerId: payload.container?.value,
//             firstLink: payload.firstLink,
//             secondLink: payload.secondLink,
//             thirdLink: payload.thirdLink,
//             isVisible: +payload.isVisible.value,
//             deliverySystems: deliverySystems,
//             recommendations: recommendations,
//         });

//         if (validator?.titleValidator) {
//             await axios.put(`/content-data/${contentDataId}/?responseType=${responseType}`, {
//                 contentId: contentId,
//                 language: payload.contentBlocks[0].language,
//                 title: payload.title?.trim() === "" ? null : payload.title,
//                 imagePath: payload.imagePath || null,
//             });
//         }

//         if (validator?.contentBlocksValidator) {
//             const currentBlocks = await Promise.all(
//                 payload?.contentBlocks.map(async (contentBlock, index) => {
//                     let blockImagePath: string | File | FileList | null = contentBlock.imagePath;

//                     // Handle image upload for content block image
//                     if (blockImagePath instanceof File) {
//                         blockImagePath = await handleImageUpload(blockImagePath, 'contentBlock');
//                     } else if (blockImagePath instanceof FileList && blockImagePath.length > 0) {
//                         blockImagePath = await handleImageUpload(blockImagePath, 'contentBlock');
//                     } else if (blockImagePath && typeof blockImagePath === 'object' && Object.keys(blockImagePath).length === 0) {
//                         // If it's an empty object, set it to null
//                         blockImagePath = null;
//                     }

//                     return {
//                         ...contentBlock,
//                         contentId: contentId,
//                         imagePath: blockImagePath,
//                         title: contentBlock.title?.trim() === "" ? null : contentBlock.title,
//                         index: index + 1,
//                     };
//                 })
//             );

//             const blocksToUpdate = currentBlocks.filter((i) => i.id !== undefined);
//             const blocksToCreate = currentBlocks.filter((i) => i.id === undefined);

//             if (blocksToUpdate.length > 0) {
//                 await axios.put(`/content-blocks/bulk/?responseType=${responseType}`, {
//                     validatedData: blocksToUpdate,
//                 });
//             }
//             if (blocksToCreate.length > 0) {
//                 await axios.post(`/content-blocks/bulk/?responseType=${responseType}`, {
//                     validatedData: blocksToCreate,
//                 });
//             }
//         }

//         return { title: payload.title, contentId: contentId, containerId: payload.container?.value };
//     } catch (error) {
//         toast.error(i18next.t('errors:default'));
//     }
// };

export const deleteContentDetail = async (id: number): Promise<number> => {
    const response = await axios.delete(`/contents/${id}`)
    return response.status
}

export const getReferencesCatalogs = async (
    responseType: string,
    parentDeliverySystemContentId: number,
    relatedDeliverySystemContentId: number,
    search: string
): Promise<ContentReferencesType> => {
    const response = await axios.get(
        `/recommendations/?responseType=${responseType}&parentDeliverySystemContentId=${parentDeliverySystemContentId}&relatedDeliverySystemContentId=${relatedDeliverySystemContentId}&search=${search}&limit=50`
    )
    return ContentReferencesSchema.parse(response.data)
}

export const getDeliverySystemContents = async (
    responseType: string,
    containerId?: string | null,
    contentId?: number | null
): Promise<DeliverySystemContentsType> => {
    const response = await axios.get(
        `/delivery-system-contents/?responseType=${responseType}&contentId=${contentId}&containerId=${containerId}&language=it_IT`
    )
    return DeliverySystemContentsSchema.parse(response.data)
}
