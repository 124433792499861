import styled, {css} from 'styled-components'
import {spacing} from '@/theme/theme.ts'

export const DESKTOP_TABLE_COLUMNS_PADDING = `${spacing * 4}px`
export const DESKTOP_TABLE_SCROLLBAR_WIDTH = '4px'

type StyledDesktopTableProps = {
    $customHeight?: number
}

export const StyledDesktopTable = styled.div<StyledDesktopTableProps>(
    ({theme: {palette}}) => css`
        width: 100%;
        height: 100vh;
        max-height: calc(100vh - 285px);
        display: grid;
        grid-template-rows: auto 1fr;
        border: 1px solid ${palette.neutral[200]};
        border-radius: 12px;
        background: ${palette.neutral.white};
        overflow-y: auto;
    `
)
