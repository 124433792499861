import {Outlet} from 'react-router-dom'
import {StyledMain, StyledPrivateLayout} from './styles'
import React from 'react'
import {BreadCrumbs} from '@components/commons/bread-crumbs/BreadCrumbs'
import {Container} from '@components/ui/container/Container.tsx'
import {Navbar} from '@/components/commons/nav-bar/Navbar.tsx'

export const PrivateLayout: React.FC = () => {
    return (
        <StyledPrivateLayout>
            <StyledMain>
                <Navbar />
            </StyledMain>
            <StyledMain>
                <Container fullHeight={false}>
                    <BreadCrumbs />
                </Container>
                <Outlet />
            </StyledMain>
        </StyledPrivateLayout>
    )
}
