import styled, {css} from 'styled-components'

export const DashboardCardsContainer = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(318px, 1fr));
        gap: ${spacing * 10}px;
        padding: ${spacing * 10}px 0px;
    `
)

export const DashboardCardContainer = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-flow: column;
        border-radius: 8px;
        border: 1px solid #e5e5e5;
        flex: 1;
        min-width: 320px;
        max-width: 320px;
        margin: 0 auto;
        & > h2 {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            color: #141414;
            padding: ${spacing * 4}px ${spacing * 2}px;
            padding-bottom: 0;
        }
    `
)

export const DashboardCardsInfoContainer = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-flow: column;
        padding: ${spacing * 2}px;
        gap: ${spacing * 4}px;
    `
)

export const DashboardCardsItemsContainer = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 10}px;
        justify-content: space-between;
        & > p {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }
        & > span {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
        }
    `
)

export const DashboardStatusContainer = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing}px;
        & > span {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
        }
    `
)
