import {z} from 'zod'

export const User = z.object({
    id: z.number(),
    email: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    role: z.string(),
    isActive: z.boolean(),
    lastLoginAt: z.string(),
    createdAt: z.string(),
    updatedAt: z.string()
})
export type User = z.infer<typeof User>

const AuthState = z.object({
    user: User.nullable(),
    accessToken: z.string(),
    refreshToken: z.string()
})
export type AuthState = z.infer<typeof AuthState>
