import styled, {css, DefaultTheme} from 'styled-components'

export const StyledPrivateLayout = styled.div`
    height: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    padding: 24px;
`

export const StyledMain = styled.div(
    ({theme: {spacing}}: {theme: DefaultTheme}) => css`
        overflow: hidden;
        height: 100%;
        padding: ${spacing * 6}px 0;
        display: flex;
        flex-direction: column;
        gap: ${spacing * 5}px;
    `
)
