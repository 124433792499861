import styled, {css, DefaultTheme} from 'styled-components'

export const StyledProfileForm = styled.form`
    ${({theme: {spacing}}: {theme: DefaultTheme}) => css`
        display: flex;
        flex-flow: column;
        gap: ${spacing * 6}px;
    `}
`

export const StyledFormInputContainer = styled.div`
    ${({theme: {spacing}}: {theme: DefaultTheme}) => css`
        display: flex;
        align-items: flex-start;
        gap: ${spacing * 6}px;
    `}
`
export const StyledRecoveryPasswordText = styled.div`
    ${({theme: {palette}}: {theme: DefaultTheme}) => css`
        & > p {
            display: inline;
        }

        & > span {
            cursor: pointer;
            color: ${palette.primary[600]};
            border-bottom: 1px solid ${palette.primary[600]};
        }
    `}
`
