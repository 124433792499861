import React from 'react'
import {Button} from '@components/ui/button/Button.tsx'
import {Container} from '@components/ui/container/Container.tsx'
import {StyledLoginBox, StyledLoginForm, StyledLoginWrapper} from '@pages/login/style.ts'
import {useTranslation} from 'react-i18next'
import Logo from '@assets/images/logo_login.svg'

import {zodResolver} from '@hookform/resolvers/zod'
import {useMutation} from '@tanstack/react-query'
import {useForm} from 'react-hook-form'
import {InputText} from '@/components/commons/input-text/InputText'
import {userLogin} from '@/features/auth/services/auth.http'
import {useAuthStore} from '@/features/auth/store/store'
import {errorHandler} from '@/utilities/genericErrorHandler'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {LoginRequest, LoginRequestSchema, LoginResponse} from '@/features/auth/services/LoginSchema'
import {useNavigate} from 'react-router-dom'

export const Login: React.FC = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const {
        handleSubmit,
        register,
        formState: {errors, touchedFields}
    } = useForm<LoginRequest>({
        defaultValues: {
            email: '',
            password: ''
        },
        resolver: zodResolver(LoginRequestSchema),
        mode: 'onBlur'
    })

    const mutation = useMutation({
        retry: false,
        mutationKey: ['login'],
        mutationFn: (data: LoginRequest) => userLogin(data),
        onSuccess: (data: LoginResponse) => {
            console.log(data)
            useAuthStore.setState(() => ({
                accessToken: data.accessToken,
                refreshToken: data.refreshToken,
                user: data.user
            }))
        },
        onError: e => {
            errorHandler(e)
        }
    })

    const onSubmit = (data: LoginRequest) => {
        const {email, password} = data
        mutation.mutate({
            email: email,
            password: password
        })
    }

    return (
        <Container>
            <StyledLoginWrapper>
                <StyledLoginBox>
                    <img src={Logo} alt={'logo'} height={80} />
                    <h4>{t('login:title')}</h4>
                    <p>{t('login:subtitle')}</p>
                    <StyledLoginForm onSubmit={handleSubmit(onSubmit)}>
                        <InputText
                            label={t('login:form:email')}
                            type={'text'}
                            touched={touchedFields.email}
                            errorMessage={errors?.email?.message || ''}
                            placeholder={t('login:form:email')}
                            {...register('email')}
                            width={'360px'}
                            autoComplete={'off'}
                            name={'email'}
                        />

                        <InputText
                            label={t('login:form:password')}
                            type={'password'}
                            touched={touchedFields.password}
                            errorMessage={errors?.password?.message || ''}
                            placeholder={t('login:form:password')}
                            {...register('password')}
                            visibilityToggle={true}
                            width={'360px'}
                            autoComplete={'off'}
                            name={'password'}
                        />
                        <p onClick={() => navigate('/forgot-password')}>{t('login:forgot_password')}</p>
                        <Button type="submit" name="Submit" variant={'primary'} size={'lg'}>
                            {!mutation.isPending ? t('login:cta') : <Spinner size={16} />}
                        </Button>
                    </StyledLoginForm>
                </StyledLoginBox>
            </StyledLoginWrapper>
        </Container>
    )
}

Login.displayName = 'Login'
