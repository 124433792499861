import {PageHero} from '@/components/commons/page-hero/PageHero'
import {UsersTable} from '@/components/commons/users-table/UsersTable'
import {Container} from '@/components/ui/container/Container'
import {UsersRequest} from '@/features/profile/ProfileSchema'
import {getUsers} from '@/features/profile/profile'
import {LangType} from '@/utilities/constants/commons'
import {errorHandler} from '@/utilities/genericErrorHandler'
import {useInfiniteQuery} from '@tanstack/react-query'
import {useState, useMemo, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'
import {StyledContainer} from './style'

export const Users = () => {
    const {t} = useTranslation()
    const location = useLocation()
    const [isAscOrder, setIsAscOrder] = useState(true)

    const currentLocation = useMemo(() => {
        return location.pathname.replace('/', '')
    }, [location.pathname])

    const {data, error, isFetching, isFetchingNextPage, fetchNextPage} = useInfiniteQuery<UsersRequest>({
        queryKey: ['userTable'],
        queryFn: async ({pageParam}) => {
            const response = await getUsers('regular', LangType.it, (pageParam as number) || 1)
            return response
        },
        initialPageParam: 1,
        getPreviousPageParam: firstPage => firstPage.meta.previous_page_url ?? undefined,
        getNextPageParam: lastPage => lastPage.meta.next_page_url ?? undefined
    })

    useEffect(() => {
        if (error) {
            errorHandler(error)
        }
    }, [error])

    return (
        <Container>
            <PageHero title={t(`routes:users`)} subtitle={''} />
            <StyledContainer>
                <UsersTable
                    data={data}
                    isFetching={isFetching}
                    isAscOrder={isAscOrder}
                    setIsAscOrder={setIsAscOrder}
                    detailLink={currentLocation}
                    isFetchingNextPage={isFetchingNextPage}
                    fetchNextPage={fetchNextPage}
                />
            </StyledContainer>
        </Container>
    )
}

Users.displayName = 'Users'
