import {httpSSOLogin, HttpSSOResponse, httpLogout, httpUserMe} from '@/features/auth/services/auth.http'
import toast from 'react-hot-toast'
import i18next from 'i18next'
import {useAuthStore} from '@/features/auth/store/store.ts'
import {parseAxiosPromise} from '@/axiosInstance.ts'
import {captureException} from '@sentry/react'
import {User} from '@/features/auth/types.ts'
import {LOCAL_STORAGE_ACCESS_TOKEN, LOCAL_STORAGE_REFRESH_TOKEN} from '@/features/auth/constants.ts'

export const validateSSOUser = async (SSOCode?: string | null) => {
    if (!SSOCode) {
        throw new Error('code is required')
    }

    try {
        const {oauth2_authorization} = await parseAxiosPromise({
            axiosPromise: httpSSOLogin(SSOCode),
            onZodError: captureException,
            responseShape: HttpSSOResponse
        })
        localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, oauth2_authorization.access_token)
        localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, oauth2_authorization.refresh_token)
        useAuthStore.setState(() => ({
            accessToken: oauth2_authorization.access_token,
            refreshToken: oauth2_authorization.refresh_token
        }))
    } catch (e) {
        toast.error(i18next.t('errors:default'))
    }
}

export const userMe = async () => {
    try {
        const user = await parseAxiosPromise({
            axiosPromise: httpUserMe(),
            onZodError: captureException,
            responseShape: User
        })
        useAuthStore.setState(() => ({user}))
    } catch (e) {
        toast.error(i18next.t('errors:default'))
    }
}

export const logout = async () => {
    try {
        await httpLogout()
        localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN)
        localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN)
        useAuthStore.setState(() => ({user: null, accessToken: undefined, refreshToken: undefined}))
    } catch (e) {
        toast.error(i18next.t('errors:default'))
    }
}
