import styled, {css} from 'styled-components'

export const NavbarWrapper = styled.div`
    ${({theme: {palette}}) => css`
        box-sizing: border-box;
        background: ${palette.primary['50']};
        border-bottom: 1px solid ${palette.neutral['200']};
        height: 100%;
        border-radius: 24px;
        width: 312px;
        overflow-y: auto;
        padding: 24px 0px;
    `}
`

export const NavbarGrid = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-flow: column;
        gap: ${spacing * 14}px;
        height: 100%;
        align-items: flex-start;
        width: 100%;
    `
)

export const MenuItems = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-flow: column;
        gap: ${spacing * 2}px;
        width: 100%;
    `}
`

export const AvatarContainer = styled.div`
    ${({theme: {spacing}}) => css`
        margin-top: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: -webkit-fill-available;
        margin-left: 24px;
        margin-right: 24px;
        padding: 16px 0px;
        border-top: 1px solid #fdead7;
        gap: ${spacing * 2}px;
    `}
`

export const AvatarNameContainer = styled.div`
    ${() => css`
        display: flex;
        flex-flow: column;
        cursor: pointer;
        & > .name {
            font-weight: 600;
            color: #772917;
            font-size: 14px;
        }
        & > .email {
            font-size: 14px;
            font-weight: 400;
            color: #772917;
        }
    `}
`

export const DropdownUserInfosItems = styled.div`
    ${({theme: {spacing, palette, typography}}) => css`
        display: grid;
        align-items: center;
        gap: ${spacing}px;
        & span {
            ${typography.textSm};
            font-weight: 600;
            color: ${palette.neutral['700']};
        }
        & small {
            ${typography.textSm};
            font-weight: 400;
            color: ${palette.neutral['600']};
        }
        & p {
            ${typography.textXs};
            color: ${palette.neutral['400']};
        }
    `}
`
