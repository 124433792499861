import {StyledErrorWrapper} from '@components/commons/error-box/style.ts'
import {FC} from 'react'
import {NotResultIcon} from '@/components/ui/icon/uiIcons'

interface ErrorBoxProps {
    title: string
    description: string
}
export const EmptyResult: FC<ErrorBoxProps> = ({title, description}) => {
    return (
        <StyledErrorWrapper>
            <NotResultIcon />
            <h1>{title}</h1>
            <p>{description}</p>
        </StyledErrorWrapper>
    )
}

EmptyResult.displayName = 'EmptyResult'
