import {ContentsTable} from '@/components/commons/contents-table/ContentsTable'

import {PageHero} from '@/components/commons/page-hero/PageHero'

import {Container} from '@/components/ui/container/Container'
import {ContentsResponse} from '@/features/contents/ContentsSchema'
import {getContents} from '@/features/contents/contents'
import {LangType} from '@/utilities/constants/commons'

import {errorHandler} from '@/utilities/genericErrorHandler'

import {useInfiniteQuery} from '@tanstack/react-query'
import {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'
import {StyledContainer} from './style'

export const ContentsContainer = () => {
    const {t} = useTranslation()
    const location = useLocation()
    const idParams = new URLSearchParams(location.search)
    const id = idParams.get('id')

    const [isAscOrder, setIsAscOrder] = useState(true)
    // const [searchParams, setSearchParams] = useState<string>('')

    const currentLocation = useMemo(() => {
        return location.pathname.replace('/', '')
    }, [location.pathname])

    const {data, error, isFetching, isFetchingNextPage, fetchNextPage} = useInfiniteQuery<ContentsResponse>({
        queryKey: ['contents-list', id],
        queryFn: async ({pageParam}) => {
            const response = await getContents('regular', LangType.it, id ? +id : 1, (pageParam as number) || 1, '')
            return response
        },
        initialPageParam: 1,
        getPreviousPageParam: previousPage =>
            previousPage.meta.previous_page_url !== null
                ? +previousPage.meta.previous_page_url?.split('=')[1]
                : undefined,
        getNextPageParam: lastPage =>
            lastPage.meta.next_page_url !== null ? +lastPage.meta.next_page_url?.split('=')[1] : undefined
    })

    useEffect(() => {
        if (error) {
            errorHandler(error)
        }
    }, [error])

    return (
        <Container>
            <PageHero title={t(`routes:${currentLocation}`)} subtitle={''} />
            <StyledContainer>
                <ContentsTable
                    data={data}
                    isFetching={isFetching}
                    isAscOrder={isAscOrder}
                    setIsAscOrder={setIsAscOrder}
                    detailLink={currentLocation}
                    containerId={id}
                    isFetchingNextPage={isFetchingNextPage}
                    fetchNextPage={fetchNextPage}
                />
            </StyledContainer>
        </Container>
    )
}

ContentsContainer.displayName = 'ContentsContainer'
