import styled, {css, DefaultTheme} from 'styled-components'
import {Container} from '@components/ui/container/Container.tsx'
import {XCloseIcon} from '@/components/ui/icon/Icon'

type StyledLangTabProps = {
    $isActive?: boolean
    $isEmpty?: boolean
    $isIt?: boolean
}

type StyledAnimatedCardProps = {
    $isAnimating?: boolean
}

export const StyledContainer = styled(Container)`
    ${({theme: {spacing}}: {theme: DefaultTheme}) => css`
        display: grid;
        grid-template-rows: auto auto 1fr;
        gap: ${spacing * 6}px;
    `}
`

export const Title = styled.h1(
    ({theme: {typography, palette}}) => css`
        ${typography.displaySm};
        font-weight: 600;
        height: 38px;
        color: ${palette.neutral['900']};
    `
)

export const TabsWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 4}px;
        max-width: 664px;
        border-bottom: 1px solid #d6d6d6;
    `
)

export const StyledLangTab = styled.div<StyledLangTabProps>(
    ({theme: {palette}, $isActive, $isEmpty, $isIt}) => css`
        height: 32px;
        flex: 1;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        color: ${$isActive ? palette.primary['700'] : palette.neutral['500']};
        cursor: ${$isIt ? 'none' : 'pointer'};
        border-bottom: 2px solid ${$isActive ? palette.primary['700'] : 'transparent'};
        opacity: ${$isEmpty ? 0.5 : 1};
        pointer-events: ${$isIt ? 'none' : 'auto'};
    `
)

export const ContentCardContainer = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-flow: column;
        gap: ${spacing * 6}px;
        border: 1px solid #d6d6d6;
        padding: 32px 16px;
        border-radius: 12px;
        & > .input-title {
            font-size: 20px;
            font-weight: 600;
            color: #000000;
        }
    `
)

export const ContentInputContainer = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: auto 1fr;
        gap: ${spacing * 6}px;
        & > .input-label {
            font-size: 16px;
            font-weight: 600;
            color: #000000;
            width: 100px;
        }
    `
)
export const ContentLinkContainer = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-flow: column;
        gap: ${spacing + 2}px;
    `
)

export const StyledForm = styled.form(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-flow: column;
        gap: ${spacing * 12}px;
    `
)

export const StyledFormItemsContainer = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 664px 320px;
        gap: ${spacing * 8}px;
    `
)

export const StyledFormItemContainer = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-flow: column;
        flex: 1;
        width: 100%;
        max-width: 664px;
        gap: ${spacing * 8}px;
        & > .ctaContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: ${spacing * 6}px;
            & > .title {
                font-weight: 600;
                font-size: 18px;
                color: #101828;
            }
        }
        &.propsContainer {
            margin-top: -66px;
        }
    `
)

export const StyledScrollForm = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-flow: column;
        gap: ${spacing * 6}px;
        overflow-y: auto;
        padding-top: 24px;
        height: calc(100vh - 272px);
        scrollbar-width: none; /* Nasconde lo scrollbar in Firefox */
        -ms-overflow-style: none; /* Nasconde lo scrollbar in IE/Edge */
        &::-webkit-scrollbar {
            display: none; /* Nasconde lo scrollbar in Chrome/Safari */
        }
    `
)

export const StyledScrollRightForm = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-flow: column;
        gap: ${spacing * 6}px;
        overflow-y: auto;
        height: calc(100vh - 282px);
        scrollbar-width: none; /* Nasconde lo scrollbar in Firefox */
        -ms-overflow-style: none; /* Nasconde lo scrollbar in IE/Edge */
        &::-webkit-scrollbar {
            display: none; /* Nasconde lo scrollbar in Chrome/Safari */
        }
    `
)

export const AnimatedCard = styled(ContentCardContainer)<StyledAnimatedCardProps>(
    ({theme: {palette, spacing}, $isAnimating}) => css`
        position: relative;
        animation: ${$isAnimating ? 'slide-in 1.5s ease-in-out' : 'none'};

        @keyframes slide-in {
            from {
                opacity: 0.7;
                border: 1px solid ${palette.primary['700']};
                box-shadow: 0px 0px 5px ${palette.primary['700']};
            }
            to {
                opacity: 1;
                border: 1px solid ${palette.primary['700']};
            }
        }
        & > .label_container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 42px;
            & > p {
                flex: 1;
                font-size: 20px;
                font-weight: 600;
                color: #000000;
            }
        }
        & > .card_arrows_container {
            display: flex;
            flex: 1;
            gap: ${spacing * 2}px;
            width: 100%;
            align-items: center;
            justify-content: flex-end;
        }
        & .arrow {
            cursor: pointer;
        }
    `
)

export const StyledXcloseIcon = styled(XCloseIcon)(
    () => css`
        position: absolute;
        top: 10px;
        right: 14px;
        cursor: pointer;
    `
)
