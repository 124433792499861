import {DeleteModal} from '@/components/commons/delete-modal/DeleteModal'
import {EmptyResult} from '@/components/commons/empty-result/EmptyResult'
import Table from '@/components/commons/table/Table'
import {TableDataCell} from '@/components/commons/table/table-data-cell/TableDataCell'
import {Trash01Icon, PlusIcon} from '@/components/ui/icon/Icon'
import {ButtonLink} from '@/components/ui/button-link/ButtonLink'
import {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {InfiniteData, useMutation, useQueryClient} from '@tanstack/react-query'
import {ProfileResponse, UsersRequest} from '@/features/profile/ProfileSchema'
import {ContainerName, RoleType} from '@/utilities/constants/commons'
import {deleteProfile} from '@/features/profile/profile'
import {errorHandler} from '@/utilities/genericErrorHandler'
import toast from 'react-hot-toast'
import {VisibilityChip} from '../contents-table/style'

interface UsersTableProps {
    data: InfiniteData<UsersRequest> | undefined
    isFetching: boolean
    isAscOrder: boolean
    setIsAscOrder: (e: boolean) => void
    detailLink: string
    isFetchingNextPage: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fetchNextPage: any
}

export const UsersTable = ({
    data,
    isFetching,
    isAscOrder,
    setIsAscOrder,
    detailLink,
    isFetchingNextPage,
    fetchNextPage
}: UsersTableProps) => {
    const {t} = useTranslation()
    const queryClient = useQueryClient()
    const [deleteUser, setDeleteUser] = useState<ProfileResponse | undefined>(undefined)

    const remappedData = useMemo(() => {
        if (data?.pages) {
            return data.pages.flatMap(page => page.data)
        } else {
            return []
        }
    }, [data?.pages[0]])

    const sortedData = remappedData?.sort((a, b) => {
        if (isAscOrder) {
            return a.firstName.localeCompare(b.firstName)
        } else {
            return b.firstName.localeCompare(a.firstName)
        }
    })

    const deleteMutation = useMutation({
        retry: false,
        mutationKey: ['delete-profile'],
        mutationFn: (id: number) => deleteProfile(id),
        onSuccess: () => {
            toast.success('Profilo eliminato con successo')
            setDeleteUser(undefined)
            queryClient.invalidateQueries({queryKey: ['userTable']})
        },
        onError: e => {
            errorHandler(e)
        }
    })

    return (
        <>
            <Flexbox gap={2} align="center" justify="space-between">
                <div />
                <ButtonLink variant={'primary'} size={'md'} to={`/${detailLink}/${ContainerName.newUser}`}>
                    <PlusIcon />
                    {t('table:createUser')}
                </ButtonLink>
            </Flexbox>
            <Table
                onChangePage={fetchNextPage}
                isChangingPage={isFetchingNextPage}
                data={sortedData || []}
                isLoading={isFetching}
                sorter={{
                    orderBy: 'name',
                    orderDirection: isAscOrder ? 'asc' : 'desc',
                    onSort: () => setIsAscOrder(!isAscOrder)
                }}
                columns={[
                    {
                        label: t('table:name'),
                        width: '30%',
                        sortName: 'name',
                        cellRender: item => (
                            <TableDataCell title={'Name'}>
                                <Link
                                    to={`/${detailLink}/${item?.firstName.toLowerCase()}-${item?.lastName.toLowerCase()}?id=${item.id}`}
                                    style={{color: '#141414', fontWeight: '600'}}
                                >
                                    {item?.firstName} {item.lastName}
                                </Link>
                            </TableDataCell>
                        )
                    },
                    {
                        label: t('table:status'),
                        width: '16%',
                        cellRender: item => (
                            <TableDataCell title={'Status'}>
                                <VisibilityChip $isVisible={item.isActive}>
                                    <span /> {item.isActive ? t('table:active') : t('table:notActive')}
                                </VisibilityChip>
                            </TableDataCell>
                        )
                    },
                    {
                        label: t('table:email'),
                        width: '22%',
                        cellRender: item => <TableDataCell title={'Email'}>{item.email}</TableDataCell>
                    },
                    {
                        label: t('table:role'),
                        width: '25%',

                        cellRender: item => (
                            <TableDataCell title={'Role'}>
                                {item.role === RoleType.editor ? (
                                    <div>{t('table:editor')}</div>
                                ) : (
                                    <div>{t('table:admin')}</div>
                                )}
                            </TableDataCell>
                        )
                    },
                    {
                        label: '',
                        width: '7%',
                        cellRender: item => (
                            <TableDataCell title={'delete'}>
                                {item.role !== RoleType.admin && (
                                    <Trash01Icon onClick={() => setDeleteUser(item)} style={{cursor: 'pointer'}} />
                                )}
                            </TableDataCell>
                        )
                    }
                ]}
                emptyStateComponent={<EmptyResult title={t('empty:title')} description={t('empty:description')} />}
                emptySearchStateComponent={
                    <EmptyResult title={t('empty:title')} description={t('empty:description')} />
                }
            />
            {deleteUser && (
                <DeleteModal
                    isLoading={deleteMutation.isPending}
                    onDelete={() => {
                        deleteMutation.mutate(deleteUser.id)
                    }}
                    onClose={() => setDeleteUser(undefined)}
                    title={`${t('modal:deleteUser:title')} ${deleteUser.firstName} ${deleteUser.lastName}`}
                    paragraph={t('modal:deleteUser:description')}
                />
            )}
        </>
    )
}
