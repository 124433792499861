import axios from '@/axiosInstance.ts'
import {
    ProfileCreateRequest,
    ProfileRequest,
    ProfileResponse,
    ProfileResponseSchema,
    UsersRequestSchema
} from './ProfileSchema'

export const getUsers = async (responseType: string, language: string, page: number) => {
    const response = await axios.get(`/users/?responseType=${responseType}&language=${language}&page=${page}&limit=20`)
    return UsersRequestSchema.parse(response.data)
}

export const getProfileDetail = async (responseType: string, id?: number): Promise<ProfileResponse> => {
    const response = await axios.get(`/users/${id}/?responseType=${responseType}`)
    return ProfileResponseSchema.parse(response.data)
}

export const createProfile = async (payload: ProfileCreateRequest): Promise<ProfileResponse> => {
    const response = await axios.post(`/auth/signup/`, {
        email: payload.email,
        firstName: payload.firstName,
        lastName: payload.lastName,
        role: payload.role,
        isActive: payload.isActive,
        password: payload.password,
        passwordConfirmation: payload.passwordConfirmation
    })
    return ProfileResponseSchema.parse(response.data)
}

export const updateProfile = async (payload: ProfileRequest): Promise<ProfileResponse> => {
    const response = await axios.put(`/users/${payload.id}`, {
        email: payload.email,
        firstName: payload.firstName,
        lastName: payload.lastName,
        role: payload.role,
        isActive: payload.isActive
    })
    return ProfileResponseSchema.parse(response.data)
}

export const deleteProfile = async (id: number): Promise<number> => {
    const response = await axios.delete(`/users/${id}`)
    return response.status
}
