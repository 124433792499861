import {Link, useLocation} from 'react-router-dom'
import {StyledBreadCrumbs} from '@components/commons/bread-crumbs/style.ts'
import {HomeLineIcon} from '@components/ui/icon/Icon.tsx'
import {Fragment, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {ContainerName} from '@/utilities/constants/commons'

export const BreadCrumbs = () => {
    const {t} = useTranslation()
    const location = useLocation()
    const paths = location.pathname.split('/').filter(path => path)
    const [initialSearch, setInitialSearch] = useState('')

    useEffect(() => {
        if (paths.length === 1) {
            setInitialSearch(location.search)
        }
    }, [location.pathname, location.search, paths])

    return (
        <StyledBreadCrumbs>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Link to="/">
                    <HomeLineIcon data-color size={20} />
                </Link>
                {paths.map((path, index) => (
                    <Fragment key={index}>
                        <span>/</span>
                        {index === 0 && (
                            <Link to={`/${paths.slice(0, index + 1).join('/')}${index === 0 ? initialSearch : ''}`}>
                                {`${t(`routes:${path}`)}`}
                            </Link>
                        )}
                        {index === 1 && path === ContainerName.newContent && t('routes:new-content')}
                        {index === 1 && path === ContainerName.newUser && t('routes:new-user')}
                        {index === 1 &&
                            path !== ContainerName.newContent &&
                            path !== ContainerName.newUser &&
                            t('table:edit')}
                    </Fragment>
                ))}
            </div>
        </StyledBreadCrumbs>
    )
}

BreadCrumbs.displayName = 'BreadCrumbs'
