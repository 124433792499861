import {Modal} from '@components/ui/modal/Modal.tsx'
import {StyledDeleteUserModal, StyledContent, StyledFooter} from '@components/commons/delete-modal/style.ts'
import {FC, ReactNode} from 'react'
import {Button} from '@components/ui/button/Button.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {useTranslation} from 'react-i18next'
import {DefaultNamespace} from 'i18next'

interface DeleteUserModalProps {
    isLoading: boolean
    onClose: () => void
    onDelete: () => void
    title: string | DefaultNamespace
    paragraph: ReactNode
}

export const DeleteModal: FC<DeleteUserModalProps> = ({isLoading, onClose, onDelete, title, paragraph}) => {
    const {t} = useTranslation()

    return (
        <Modal closeIconOffset="sm" onClose={onClose} onOverlayClick={onClose}>
            <StyledDeleteUserModal>
                <StyledContent>
                    <h3>{title}</h3>
                    <p>{paragraph}</p>
                </StyledContent>
                <StyledFooter>
                    <Button variant="secondary" onClick={onClose} disabled={isLoading}>
                        {t('commons:cancel')}
                    </Button>
                    <Button variant="primaryDanger" onClick={onDelete} disabled={isLoading}>
                        {t('commons:confirm')}
                        {isLoading && <Spinner size={16} />}
                    </Button>
                </StyledFooter>
            </StyledDeleteUserModal>
        </Modal>
    )
}

DeleteModal.displayName = 'DeleteModal'
