import {createGlobalStyle, withTheme} from 'styled-components'
import {palette} from '@/theme/palette.ts'

const ResetStyles = createGlobalStyle`
  * {
  '-ms-overflow-style': 'none' /* for Internet Explorer, Edge */,
  ' scrollbar-width': 'none' /* for Firefox */,
  }

  @font-face {
    font-family: 'Playfair Display';
  src: url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400&display=swap');
  font-weight: 400;
  font-style: normal;
  }

  @font-face {
    font-family: 'Playfair Display';
  src: url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap');
  font-weight: 600;
  font-style: normal;
  }

  @font-face {
    font-family: 'Playfair Display';
  src: url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap');
  font-weight: 700;
  font-style: normal;
  }

  @font-face {
font-family: 'Lato';
src: url('https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap');
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'Lato';
src: url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');
font-weight: 700;
font-style: normal;
}

  html{
    height: 100%;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    color: ${palette.light.neutral[500]};
    background-color: ${palette.light.neutral.white};
  '-webkit-font-smoothing': 'antialiased',
  '-moz-osx-font-smoothing': 'grayscale',
  }

  body {
    height: 100%;
    word-break: break-word;
  }

  #root {
    height: 100%;
  }
  
  h1, h2, h3, h4, h5, h6, p, span, small {
    margin-bottom: 0;
  }
  label, input, textarea, select, button {
    font-family: inherit;
  }
  
  textarea {
    overflow-wrap: break-word;
  }
  
  //Reset Css

  /* Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property */
  *:where(:not(iframe, canvas, img, svg, video):not(svg *)) {
    all: unset;
    display: revert;
  }

  /* Preferred box-sizing value */
  *, *::before, *::after {
    box-sizing: border-box;
  }

  /* Remove list styles (bullets/numbers) */
  ol, ul {
    list-style: none;
  }

  /* For images to not be able to exceed their container */
  img {
    max-width: 100%;
  }
  
  svg {
    flex-shrink: 0;
  }

  /* Removes spacing between cells in tables */
  table {
    border-collapse: collapse;
  }

  /* Revert the 'white-space' property for textarea elements on Safari */
  textarea {
    white-space: revert;
  }
  
  /* Change the white to any color */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`

export default withTheme(ResetStyles)
