import styled, {css} from 'styled-components'
import {Container} from '@components/ui/container/Container.tsx'

export const StyledContainer = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const StyledErrorWrapper = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        gap: ${spacing}px;
        width: 100%;
        padding: ${spacing * 6}px;
        border: 1px solid #eaecf0;
        border-radius: 10px;

        & h1 {
            font-size: 16px;
            font-weight: 600;
            color: #141414;
            text-align: center;
        }
        & p {
            font-size: 14px;
            font-weight: 400;
            color: ${palette.neutral['900']};
            text-align: center;
            color: #525252;
        }
    `}
`
