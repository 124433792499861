import {MetaSchema} from '@/features/commons/Commons'
import {z} from 'zod'
import {DeliverySystemSchema} from '../delivery/services/DeliverySchema'

export const ContentReferenceSchema = z.object({
    id: z.number(),
    parentContentId: z.number(),
    relatedContentId: z.number(),
    deliverySystemId: z.number().nullable(),
    relatedContent: z.object({
        id: z.number(),
        containerId: z.number(),
        firstLink: z.string().nullable(),
        secondLink: z.string().nullable(),
        thirdLink: z.string().nullable(),
        isVisible: z.number(),
        imagePath: z.string().nullable(),
        createdAt: z.string(),
        updatedAt: z.string()
    })
})

export type ContentReferenceType = z.infer<typeof ContentReferenceSchema>

export const ContentReferencesSchema = z.array(ContentReferenceSchema)

export type ContentReferencesType = z.infer<typeof ContentReferencesSchema>

export const ContentDataSchema = z.object({
    id: z.number(),
    contentId: z.number(),
    title: z.string(),
    language: z.string(),
    createdAt: z.string(),
    updatedAt: z.string()
})

export const ContainerDataSchema = z.object({
    id: z.number(),
    containerId: z.number(),
    title: z.string(),
    text: z.string(),
    language: z.string(),
    createdAt: z.string(),
    updatedAt: z.string()
})

export const ContainerDataResponseSchema = z.object({
    id: z.number(),
    contentId: z.number(),
    title: z.string(),
    language: z.string(),
    createdAt: z.string(),
    updatedAt: z.string()
})

export const ContainerSchema = z.object({
    id: z.number(),
    categoryId: z.number().nullable(),
    imagePath: z.string().nullable(),
    createdAt: z.string(),
    updatedAt: z.string(),
    category: z.string().nullable(),
    containerData: z.array(ContainerDataSchema)
})

export const ContentBlockSchema = z.object({
    id: z.number(),
    contentId: z.number(),
    title: z.string().nullable(),
    text: z.string(),
    index: z.number(),
    language: z.string(),
    imagePath: z.string().nullable(),
    createdAt: z.string(),
    updatedAt: z.string()
})

export const ContentSchema = z.object({
    firstLink: z.string().nullable(),
    secondLink: z.string().nullable(),
    thirdLink: z.string().nullable(),
    id: z.number(),
    qrCode: z.number().nullable(),
    containerId: z.number(),
    isVisible: z.number(),
    imagePath: z.string().nullable(),
    createdAt: z.string(),
    updatedAt: z.string(),
    container: ContainerSchema,
    contentBlocks: z.array(ContentBlockSchema),
    contentData: z.array(ContentDataSchema),
    deliverySystems: z.array(DeliverySystemSchema),
    recommendations: z.array(ContentReferenceSchema).optional()
})

export type ContentResponse = z.infer<typeof ContentSchema>

export const ContentsSchema = z.object({
    meta: MetaSchema,
    data: z.array(ContentSchema)
})

export type ContentsResponse = z.infer<typeof ContentsSchema>

export const ContentBlocksSchema = z.object({
    meta: MetaSchema,
    data: z.array(ContentBlockSchema)
})

export type ContentBlocksResponse = z.infer<typeof ContentBlocksSchema>
