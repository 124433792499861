import {createBrowserRouter, createRoutesFromElements, Route} from 'react-router-dom'
import {PrivateRoute} from '@route-guards/private-route/PrivateRoute.tsx'
import {PublicRoute} from '@route-guards/public-route/PublicRoute.tsx'
import {PublicLayout} from '@layouts/public-layout/PublicLayout.tsx'
import {PrivateLayout} from '@layouts/private-layout/PrivateLayout.tsx'
import {routes} from '@utilities/constants/routes.tsx'

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/">
            <Route {...routes.NOT_FOUND} />
            <Route element={<PrivateRoute />}>
                <Route element={<PrivateLayout />}>
                    <Route {...routes.DASHBOARD} />
                    <Route {...routes.ORIENTATION} />
                    <Route {...routes.CHARACTERS} />
                    <Route {...routes.HISTORY} />
                    <Route {...routes.SPACES} />
                    <Route {...routes.BIBLIOGRAPHIC_FUNDS} />
                    <Route {...routes.NUMISMATICS} />
                    <Route {...routes.ARCHEOLOGY} />
                    <Route {...routes.SCIENTIFIC_INSTRUMENTS} />
                    <Route {...routes.USERS} />
                    <Route {...routes.PROFILE} />
                    <Route {...routes.ORIENTATION_DETAIL} />
                    <Route {...routes.CHARACTERS_DETAIL} />
                    <Route {...routes.HISTORY_DETAIL} />
                    <Route {...routes.SPACES_DETAIL} />
                    <Route {...routes.BIBLIOGRAPHIC_FUNDS_DETAIL} />
                    <Route {...routes.NUMISMATICS_DETAIL} />
                    <Route {...routes.ARCHEOLOGY_DETAIL} />
                    <Route {...routes.SCIENTIFIC_INSTRUMENTS_DETAIL} />
                    <Route {...routes.USER_DETAIL} />
                </Route>
            </Route>
            <Route element={<PublicRoute />}>
                <Route element={<PublicLayout />}>
                    <Route {...routes.LOGIN} />
                    <Route {...routes.FORGOT_PASSWORD} />
                    <Route {...routes.CHANGE_PASSWORD} />
                </Route>
            </Route>
        </Route>
    )
)
