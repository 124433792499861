import {useLocation} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {ContentForm} from '@/components/commons/content-form/ContentForm'
import {Title, StyledFormItemsContainer, TabsWrapper, StyledLangTab} from '@/components/commons/content-form/style'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {ContentResponse, ContentsResponse} from '@/features/contents/ContentsSchema'
import {getContentDetail, getContents} from '@/features/contents/contents'
import {LangType, tabs} from '@/utilities/constants/commons'
import {useQuery} from '@tanstack/react-query'
import toast from 'react-hot-toast'
import {StyledContainer} from '../not-found/style'

export const ContentDetail = () => {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get('id')
    const containerId = searchParams.get('containerId')

    const [tabLang, setTabLang] = useState(LangType.it)
    const [hasChanged, setHasChanged] = useState(false)

    const {
        data: detail,
        error,
        isLoading
    } = useQuery<ContentResponse>({
        retry: false,
        queryKey: ['get-detail', id],
        queryFn: () => getContentDetail('regular', id ? +id : undefined),
        enabled: id !== null
    })

    const {data: generalData} = useQuery<ContentsResponse>({
        retry: false,
        queryKey: ['contents-list'],
        queryFn: () => getContents('regular', LangType.it, containerId ? +containerId : 1, 1, '')
    })

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (hasChanged) {
                event.preventDefault()
                event.returnValue = ''
            }
        }
        window.addEventListener('beforeunload', handleBeforeUnload)
        return () => window.removeEventListener('beforeunload', handleBeforeUnload)
    }, [hasChanged])

    if (isLoading) return <Spinner size={40} />

    if (error) {
        toast.error(error.message)
    }

    const currentTitle =
        detail?.contentData.find(i => i.language === tabLang)?.title || 'Nessun titolo nella lingua corrente'

    return (
        <StyledContainer fullHeight>
            <Title>{currentTitle}</Title>
            <StyledFormItemsContainer>
                <TabsWrapper>
                    {tabs.map(tab => (
                        <StyledLangTab
                            key={tab.lang}
                            onClick={() => setTabLang(tab.lang)}
                            $isActive={tabLang === tab.lang}
                            $isEmpty={!detail?.contentData.find(i => i.language === tab.lang)?.title}
                            $isIt={!detail?.contentData.find(i => i.language === LangType.it)}
                        >
                            {tab.label}
                        </StyledLangTab>
                    ))}
                    <div />
                </TabsWrapper>
            </StyledFormItemsContainer>
            {tabLang === LangType.it && generalData && (
                <ContentForm
                    detail={detail}
                    lang={tabLang}
                    data={generalData}
                    hasChanged={hasChanged}
                    setHasChanged={setHasChanged}
                />
            )}
            {tabLang === LangType.en && generalData && (
                <ContentForm
                    detail={detail}
                    lang={tabLang}
                    data={generalData}
                    hasChanged={hasChanged}
                    setHasChanged={setHasChanged}
                />
            )}
            {tabLang === LangType.es && generalData && (
                <ContentForm
                    detail={detail}
                    lang={tabLang}
                    data={generalData}
                    hasChanged={hasChanged}
                    setHasChanged={setHasChanged}
                />
            )}
            {tabLang === LangType.fr && generalData && (
                <ContentForm
                    detail={detail}
                    lang={tabLang}
                    data={generalData}
                    hasChanged={hasChanged}
                    setHasChanged={setHasChanged}
                />
            )}
        </StyledContainer>
    )
}

ContentDetail.displayName = 'ContentDetail'
