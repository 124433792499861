import {z} from 'zod'
import {User} from '../types'

export const LoginResponseSchema = z.object({
    accessToken: z.string(),
    refreshToken: z.string(),
    user: User
})

export type LoginResponse = z.infer<typeof LoginResponseSchema>

export const LoginRequestSchema = z.object({
    email: z.string().email(),
    password: z.string().min(3).max(20)
})

export type LoginRequest = z.infer<typeof LoginRequestSchema>

export const ForgotRequestSchema = z.object({
    email: z.string().email()
})

export type ForgotRequest = z.infer<typeof ForgotRequestSchema>

export const ChangeRequestSchema = z.object({
    token: z.string().optional(),
    password: z.string().min(3).max(20),
    passwordConfirmation: z.string().min(3).max(20)
})

export type ChangeRequest = z.infer<typeof ChangeRequestSchema>
