import {MetaSchema} from '@/features/commons/Commons'
import {z} from 'zod'

export const DeliverySystemSchema = z.object({
    id: z.number(),
    name: z.string().nullable(),
    type: z.string(),
    imagePath: z.string().nullable(),
    createdAt: z.string(),
    updatedAt: z.string(),
    contentsCount: z.number(),
    deliverySystemData: z.array(z.any()).optional(),
    lastNewContent: z.string().nullable()
})

export type DeliverySystemType = z.infer<typeof DeliverySystemSchema>

export const DeliverySchema = z.object({
    meta: MetaSchema,
    data: z.array(DeliverySystemSchema)
})

export type DeliveryResponse = z.infer<typeof DeliverySchema>

export const DeliverySystemContentSchema = z.object({
    id: z.number(),
    deliverySystemId: z.number(),
    contentId: z.number(),
    createdAt: z.string(),
    updatedAt: z.string(),
    data: z.array(z.any()).optional()
})

export type DeliverySystemContentType = z.infer<typeof DeliverySystemContentSchema>

export const DeliverySystemContentsSchema = z.object({
    meta: MetaSchema,
    data: z.array(DeliverySystemContentSchema)
})

export type DeliverySystemContentsType = z.infer<typeof DeliverySystemContentsSchema>
