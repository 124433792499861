import {AvaiableOnType} from '@/utilities/constants/commons'
import styled, {css} from 'styled-components'

type VisibilityChipProps = {
    $isVisible?: boolean
}

type AvaiableOnChipProps = {
    $type?: AvaiableOnType
}

export const VisibilityChip = styled.div<VisibilityChipProps>(
    ({theme: {spacing}, $isVisible}) => css`
        border: 1px solid #d0d5dd;
        padding: ${spacing}px;
        border-radius: 6px;
        display: grid;
        grid-template-columns: 6px 1fr;
        align-items: center;
        gap: ${spacing}px;
        font-size: 12px;
        font-weight: 500;
        & > span {
            border-radius: 50%;
            width: 6px;
            height: 6px;
            background: ${$isVisible ? '#17B26A' : '#667085'};
        }
    `
)

export const AvaiableChip = styled.div<AvaiableOnChipProps>(
    ({theme: {spacing}, $type}) => css`
        border: 1px solid ${$type === AvaiableOnType.app ? '#B2DDFF' : '#ABEFC6'};
        background-color: ${$type === AvaiableOnType.app ? '#EFF8FF' : 'rgba(236, 253, 243, 1)'};
        color: ${$type === AvaiableOnType.app ? 'rgba(23, 92, 211, 1)' : 'rgba(7, 148, 85, 1)'};
        padding: ${spacing / 2}px ${spacing * 2}px ${spacing / 2}px ${spacing * 2}px;
        border-radius: ${spacing * 3}px;
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        gap: ${spacing - 2}px;
        font-size: 12px;
        font-weight: 500;
    `
)
export const StyledRemainingItems = styled.div(
    ({theme: {spacing}}) => css`
        border: 1px solid rgba(234, 236, 240, 1);
        background-color: rgba(249, 250, 251, 1);
        color: rgba(52, 64, 84, 1);
        font-weight: 700;
        font-size: ${spacing * 3}px;
        padding: ${spacing / 2}px ${spacing * 2}px ${spacing / 2}px ${spacing * 2}px;
        border-radius: 100%;
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        gap: ${spacing - 2}px;
    `
)
