import styled, {css} from 'styled-components'

export const StyledLogoutModal = styled.div(
    ({theme: {spacing, typography, palette}}) => css`
        padding: ${spacing * 6}px;

        & h3 {
            ${typography.textLg};
            font-weight: 600;
            color: ${palette.neutral['900']};
        }

        & p {
            ${typography.textSm};
            margin-top: ${spacing}px;
        }
    `
)

export const StyledContent = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-flow: column;
        gap: ${spacing * 4}px;
    `
)

export const StyledFooter = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        justify-content: space-between;
        gap: ${spacing * 4}px;
        margin-top: ${spacing * 8}px;
    `
)
