import {create} from 'zustand'
import {persist} from 'zustand/middleware'
import {User} from '@/features/auth/types.ts'

type AuthStoreActions = {
    accessToken: string | null
    refreshToken: string | null
    user: User | null
    setAccessToken: (accessToken: string | null) => void
    setRefreshToken: (refreshToken: string | null) => void
    setUser: (user: User | null) => void
    reset: () => void
}

export const useAuthStore = create<AuthStoreActions>()(
    persist(
        set => ({
            accessToken: null,
            refreshToken: null,
            user: null,
            setAccessToken: (accessToken: string | null) => {
                set({accessToken})
            },
            setRefreshToken: (refreshToken: string | null) => {
                set({refreshToken})
            },
            setUser: (user: User | null) => {
                set({user})
            },
            reset: () => set({user: undefined, accessToken: undefined, refreshToken: undefined})
        }),
        {
            name: 'auth-storage'
        }
    )
)
