import {create} from 'zustand'
import {devtools} from 'zustand/middleware'

interface FormData {
    isVisible?:
        | {
              value?: string | undefined
              label?: string | undefined
          }
        | undefined
    contentBlocks?:
        | (
              | {
                    title?: string | null
                    imagePath?: string | File | null
                    id?: number | undefined
                    text?: string | undefined
                    language?: string | undefined
                    contentId?: number | undefined
                    index?: number | undefined
                }
              | undefined
          )[]
        | undefined
    title?: string
    imagePath?: string | File | null | undefined
    container?:
        | {
              value?: string | undefined
              label?: string | undefined
          }
        | undefined
    deliverySystems?:
        | (
              | {
                    value?: string | undefined
                    label?: string | undefined
                }
              | undefined
          )[]
        | undefined
    referencesCatalogs?:
        | (
              | {
                    label?: string | undefined
                    value?: string | undefined
                }
              | undefined
          )[]
        | undefined
    referencesApp?:
        | (
              | {
                    label?: string | undefined
                    value?: string | undefined
                }
              | undefined
          )[]
        | undefined
    referencesMuseal?:
        | (
              | {
                    label?: string | undefined
                    value?: string | undefined
                }
              | undefined
          )[]
        | undefined
    referencesMonumental?:
        | (
              | {
                    label?: string | undefined
                    value?: string | undefined
                }
              | undefined
          )[]
        | undefined
    firstLink?: string
    secondLink?: string
    thirdLink?: string
}

interface ContentFormState {
    formData: Partial<Record<string, FormData>>
    setFormData: (lang: string, data: Partial<FormData>) => void
    resetFormData: () => void
}

export const useContentFormStore = create<ContentFormState>()(
    devtools(set => ({
        formData: {},
        setFormData: (lang, data) =>
            set(state => ({
                formData: {
                    ...state.formData,
                    [lang]: {
                        ...state.formData[lang],
                        ...data
                    }
                }
            })),
        resetFormData: () => set({formData: {}})
    }))
)
