import {z} from 'zod'
import {SelectItem} from '../commons/Commons.ts'

export const BlockFormSchema = z.object({
    id: z.number().optional(),
    contentId: z.number().optional(),
    title: z.string().optional(),
    text: z.string().min(2).optional(),
    language: z.string().optional(),
    index: z.number().optional(),
    imagePath: z.custom<File | string>().nullable()
})

export type BlockForm = z.infer<typeof BlockFormSchema>

export const ContentFormSchema = z.object({
    firstLink: z.string().optional(),
    secondLink: z.string().optional(),
    thirdLink: z.string().optional(),
    title: z.string().min(2).optional(),
    imagePath: z.custom<File | string>().nullable(),
    isVisible: SelectItem,
    container: SelectItem.optional(),
    deliverySystems: z.array(SelectItem).optional(),
    referencesCatalogs: z.array(SelectItem).optional(),
    referencesMuseal: z.array(SelectItem).optional(),
    referencesMonumental: z.array(SelectItem).optional(),
    referencesApp: z.array(SelectItem).optional(),
    contentBlocks: z.array(BlockFormSchema).min(1)
})

export type ContentFormType = z.infer<typeof ContentFormSchema>

export const PayloadFormSchema = z.object({
    firstLink: z.string().optional(),
    secondLink: z.string().optional(),
    thirdLink: z.string().optional(),
    title: z.string().min(2).optional(),
    imagePath: z.custom<File | string>().nullable(),
    isVisible: SelectItem,
    container: SelectItem.optional(),
    deliverySystems: z.array(SelectItem).optional(),
    referencesCatalogs: z.array(SelectItem).optional(),
    referencesMuseal: z.array(SelectItem).optional(),
    referencesMonumental: z.array(SelectItem).optional(),
    referencesApp: z.array(SelectItem).optional(),
    contentBlocks: z.array(BlockFormSchema).min(1),
    deleteIds: z.array(z.number())
})
export type PayloadFormType = z.infer<typeof PayloadFormSchema>
