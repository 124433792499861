export enum LangType {
    it = 'it_IT',
    en = 'en_US',
    es = 'es_ES',
    fr = 'fr_FR'
}

export const tabs = [
    {label: 'Italiano', lang: LangType.it},
    {label: 'Inglese', lang: LangType.en}
    // {label: 'Spagnolo', lang: LangType.es},
    // {label: 'Francese', lang: LangType.fr}
]

export enum RoleType {
    editor = 'editor',
    admin = 'admin'
}

export const roleOptions = [
    {value: 'editor', label: 'Editor'},
    {value: 'admin', label: 'Admin'}
]

export const statusOptions = [
    {value: 'true', label: 'Attivo'},
    {value: 'false', label: 'Non attivo'}
]

export const isVisibleOptions = [
    {value: '0', label: 'Privato'},
    {value: '1', label: 'Pubblico'}
]

export const containerOptions = [
    {value: '1', label: 'Orientamento'},
    {value: '2', label: 'Personaggi'},
    {value: '3', label: 'Storia'},
    {value: '4', label: 'Spazi'},
    {value: '5', label: 'Fondi bibliografici'},
    {value: '6', label: 'Numismatica'},
    {value: '7', label: 'Archeologia'},
    {value: '8', label: 'Strumenti scientifici'},
    {value: '9', label: 'Profilo'}
]

export enum ContainerName {
    orientation = 'orientation',
    characters = 'characters',
    history = 'history',
    spaces = 'spaces',
    bibliographicFunds = 'bibliographic-funds',
    numismatics = 'numismatics',
    archeology = 'archeology',
    scientificInstruments = 'scientific-instruments',
    newContent = 'new-content',
    newUser = 'new-user'
}

export enum AvaiableOnType {
    app = 'Mobile App',
    catalogs = 'Totem Sala Cataloghi',
    museal = 'Totem Percorso Museale',
    monumental = 'Totem Salone Monumentale'
}

export const visibleOnOptions = [
    {value: '1', label: 'Totem Sala Cataloghi'},
    {value: '2', label: 'Mobile App'},
    {value: '3', label: 'Totem Percorso Museale'},
    {value: '4', label: 'Totem Salone Monumentale'}
]

export const referenceOptions = [
    {value: '1', label: 'Contenuto 1'},
    {value: '2', label: 'Contenuto 2'},
    {value: '3', label: 'Contenuto 3'}
]
