import React, {forwardRef, SVGAttributes} from 'react'

export interface IconProps extends SVGAttributes<SVGSVGElement> {
    size?: number
}

const generateIcon = (name: string, children: React.ReactElement) => {
    const Icon = forwardRef<SVGSVGElement, IconProps>(({size = 24, fill = 'currentColor', ...rest}, ref) => (
        <svg viewBox="0 0 24 24" width={size} height={size} fill={fill} ref={ref} {...rest}>
            {children}
        </svg>
    ))

    Icon.displayName = name
    return Icon
}

export const NoQrIcon = generateIcon(
    'noQrIcon',
    <g fill="none" fillRule="evenodd" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
        <g stroke="#D92D20" strokeWidth="2" transform="translate(6 6)">
            <path d="M12 0L0 12M0 0l12 12"></path>
        </g>
    </g>
)
export const UsersIcon = generateIcon(
    'usersIcon',
    <g fill="none" fillRule="evenodd" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
        <g stroke="currentColor" strokeWidth="2" transform="translate(2 3)">
            <path d="M9 12H6c-1.864 0-2.796 0-3.53.305a4 4 0 00-2.166 2.164C0 15.204 0 16.136 0 18l9-6zM13.5.29A4.001 4.001 0 0116 4L13.5.29zM10 18.5l2.025-.405c.176-.035.265-.053.347-.085a.994.994 0 00.207-.111c.073-.05.136-.114.264-.242L19.5 11a1.414 1.414 0 10-2-2l-6.658 6.658c-.127.127-.19.19-.241.263a1.001 1.001 0 00-.11.207c-.033.082-.05.17-.086.347L10 18.5zM11.5 4a4 4 0 11-8 0 4 4 0 018 0z"></path>
        </g>
    </g>
)
export const CharactersIcon = generateIcon(
    'charactersIcon',
    <g fill="none" fillRule="evenodd" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
        <g stroke="currentColor" strokeWidth="2" transform="translate(2 2)">
            <path d="M3.316 17.438A4.001 4.001 0 017 15h6a4.001 4.001 0 013.684 2.438H3.316zM14 7.5a4 4 0 11-8 0 4 4 0 018 0zm6 2.5c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0s10 4.477 10 10z"></path>
        </g>
    </g>
)
export const OreintationIcon = generateIcon(
    'oreintationIcon',
    <g fill="none" fillRule="evenodd" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
        <g stroke="currentColor" strokeWidth="2" transform="translate(2 2)">
            <path d="M20 10h-4 4zM4 10H0h4zm6-6V0v4zm0 16v-4 4zm8-10a8 8 0 11-16 0 8 8 0 0116 0zm-5 0a3 3 0 11-6 0 3 3 0 016 0z"></path>
        </g>
    </g>
)
export const SpacesIcon = generateIcon(
    'spacesIcon',
    <g fill="none" fillRule="evenodd" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
        <path
            stroke="currentColor"
            strokeWidth="2"
            d="M13 21H5.8m0 0c-1.68 0-2.52 0-3.162-.327a3 3 0 01-1.311-1.311C1 18.72 1 17.88 1 16.2M5.8 21h.4c1.68 0 2.52 0 3.162-.327a3 3 0 001.311-1.311C11 18.72 11 17.88 11 16.2v-.4c0-1.68 0-2.52-.327-3.162a3 3 0 00-1.311-1.311C8.72 11 7.88 11 6.2 11h-.4c-1.68 0-2.52 0-3.162.327a3 3 0 00-1.311 1.311C1 13.28 1 14.12 1 15.8v.4m0 0V9m8-8h4m8 8v4m-4 8c.93 0 1.395 0 1.776-.102a3 3 0 002.122-2.122C21 18.395 21 17.93 21 17m0-12c0-.93 0-1.395-.102-1.776a3 3 0 00-2.122-2.122C18.395 1 17.93 1 17 1M5 1c-.93 0-1.395 0-1.776.102a3 3 0 00-2.122 2.122C1 3.605 1 4.07 1 5"
            transform="translate(1 1)"
        ></path>
    </g>
)
export const QrIcon = generateIcon(
    'qrIcon',
    <g fill="none" fillRule="evenodd" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
        <g stroke="#079455" strokeWidth="2" transform="translate(4 4)">
            <path d="M3.833 9H8v4.166L3.833 9zM.5 13.166h1.25H.5zM10.917 9h1.666-1.666zM.5 16.5h4.167H.5zM8 .666v5-5zM12.667 16.5h1.5c.466 0 .7 0 .878-.091a.833.833 0 00.364-.364c.091-.178.091-.412.091-.879v-1.5c0-.466 0-.7-.09-.878a.833.833 0 00-.365-.364c-.178-.09-.412-.09-.878-.09h-1.5c-.467 0-.7 0-.879.09a.833.833 0 00-.364.364c-.09.178-.09.412-.09.878v1.5c0 .467 0 .7.09.879.08.157.208.284.364.364.179.09.412.09.879.09zm0-10.834h1.5c.466 0 .7 0 .878-.09a.833.833 0 00.364-.364c.091-.179.091-.412.091-.879v-1.5c0-.467 0-.7-.09-.878a.833.833 0 00-.365-.364c-.178-.091-.412-.091-.878-.091h-1.5c-.467 0-.7 0-.879.09a.833.833 0 00-.364.365c-.09.178-.09.411-.09.878v1.5c0 .467 0 .7.09.878.08.157.208.285.364.365.179.09.412.09.879.09zm-10.834 0h1.5c.467 0 .7 0 .879-.09a.833.833 0 00.364-.364c.09-.179.09-.412.09-.879v-1.5c0-.467 0-.7-.09-.878a.833.833 0 00-.364-.364C4.033 1.5 3.8 1.5 3.333 1.5h-1.5c-.466 0-.7 0-.878.09a.833.833 0 00-.364.365C.5 2.133.5 2.366.5 2.833v1.5c0 .467 0 .7.09.878.08.157.208.285.365.365.178.09.412.09.878.09z"></path>
        </g>
    </g>
)
export const EditIcon = generateIcon(
    'editIcon',
    <g fill="none" fillRule="evenodd" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
        <g stroke="#344054" strokeWidth="1.667" transform="translate(3 3)">
            <path d="M8.168 2.333h-3.5c-1.4 0-2.1 0-2.635.273A2.5 2.5 0 00.94 3.698C.668 4.233.668 4.933.668 6.333v7c0 1.4 0 2.1.272 2.635a2.5 2.5 0 001.093 1.093c.535.272 1.235.272 2.635.272h7c1.4 0 2.1 0 2.635-.272a2.5 2.5 0 001.092-1.093c.273-.535.273-1.235.273-2.635v-3.5l-7.5-7.5zm-2.5 10h1.395c.408 0 .612 0 .804-.046.17-.04.332-.108.481-.2.169-.102.313-.247.601-.535l7.969-7.969a1.768 1.768 0 00-2.5-2.5l-7.969 7.97c-.288.287-.432.431-.535.6-.092.149-.16.311-.2.481-.046.192-.046.396-.046.804v1.395z"></path>
        </g>
    </g>
)
export const HistoryIcon = generateIcon(
    'historyIcon',
    <g fill="none" fillRule="evenodd" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
        <g stroke="currentColor" strokeWidth="1.667" transform="translate(3 3)">
            <path d="M8.168 2.333h-3.5c-1.4 0-2.1 0-2.635.273A2.5 2.5 0 00.94 3.698C.668 4.233.668 4.933.668 6.333v7c0 1.4 0 2.1.272 2.635a2.5 2.5 0 001.093 1.093c.535.272 1.235.272 2.635.272h7c1.4 0 2.1 0 2.635-.272a2.5 2.5 0 001.092-1.093c.273-.535.273-1.235.273-2.635v-3.5l-7.5-7.5zm-2.5 10h1.395c.408 0 .612 0 .804-.046.17-.04.332-.108.481-.2.169-.102.313-.247.601-.535l7.969-7.969a1.768 1.768 0 00-2.5-2.5l-7.969 7.97c-.288.287-.432.431-.535.6-.092.149-.16.311-.2.481-.046.192-.046.396-.046.804v1.395z"></path>
        </g>
    </g>
)
export const SciIcon = generateIcon(
    'sciIcon',
    <g fill="none" fillRule="evenodd" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
        <g stroke="currentColor" strokeWidth="2" transform="translate(3 2)">
            <path d="M.66 16.339l4.594-4.594L.66 16.339zM16.216.782a9.5 9.5 0 010 13.435 9.5 9.5 0 01-13.435 0L16.216.782zM15 20h-10 10zm-5 0v-3 3zm5.5-12.5a6 6 0 11-12 0 6 6 0 0112 0z"></path>
        </g>
    </g>
)
export const LinkIcon = generateIcon(
    'linkIcon',
    <g fill="none" fillRule="evenodd" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
        <g stroke="#737373" strokeWidth="1.667" transform="translate(4 4)">
            <path d="M8.588 13.303L7.41 14.482a4.167 4.167 0 11-5.893-5.893l1.179-1.178m10.606 1.178l1.179-1.178a4.167 4.167 0 10-5.893-5.893L7.41 2.697m-2.328 8.22l5.834-5.834"></path>
        </g>
    </g>
)
export const CoinsIcon = generateIcon(
    'coinsIcon',
    <g fill="none" fillRule="evenodd" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
        <g stroke="currentColor" strokeWidth="2" transform="translate(2 2)">
            <path d="M13.938 13.938A7.001 7.001 0 0013 0a7.001 7.001 0 00-6.938 6.062l7.876 7.876zM5.5 11L7 10v5.5L5.5 11zm0 4.5h3-3zM14 13a7 7 0 11-14 0 7 7 0 0114 0z"></path>
        </g>
    </g>
)
export const BooksIcon = generateIcon(
    'booksIcon',
    <g fill="none" fillRule="evenodd" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
        <g stroke="currentColor" strokeWidth="2" transform="translate(2 4)">
            <path d="M10 16H3.2c-1.12 0-1.68 0-2.108-.218a2 2 0 01-.874-.874C0 14.48 0 13.92 0 12.8V3.2c0-1.12 0-1.68.218-2.108a2 2 0 01.874-.874C1.52 0 2.08 0 3.2 0h.4c2.24 0 3.36 0 4.216.436a4 4 0 011.748 1.748C10 3.04 10 4.16 10 6.4m0 9.6V6.4m0 9.6h6.8c1.12 0 1.68 0 2.108-.218a2 2 0 00.874-.874C20 14.48 20 13.92 20 12.8V3.2c0-1.12 0-1.68-.218-2.108a2 2 0 00-.874-.874C18.48 0 17.92 0 16.8 0h-.4c-2.24 0-3.36 0-4.216.436a4 4 0 00-1.748 1.748C10 3.04 10 4.16 10 6.4"></path>
        </g>
    </g>
)

export const ArcIcon = generateIcon(
    'arcIcon',
    <g fill="none" fillRule="evenodd" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
        <g stroke="currentColor" strokeWidth="2" transform="translate(6 2)">
            <path d="M12 8V1.6c0-.56 0-.84-.109-1.054a1 1 0 00-.437-.437C11.24 0 10.96 0 10.4 0H1.6C1.04 0 .76 0 .546.109a1 1 0 00-.437.437C0 .76 0 1.04 0 1.6V8m12 0H0m12 0v.2c0 1.68 0 2.52-.327 3.162a3 3 0 01-1.311 1.311C9.72 13 8.88 13 7.2 13H4.8c-1.68 0-2.52 0-3.162-.327a3 3 0 01-1.311-1.311C0 10.72 0 9.88 0 8.2V8m8.5 5v4.5a2.5 2.5 0 01-5 0V13"></path>
        </g>
    </g>
)

export const ActiveIcon = generateIcon(
    'activeIcon',
    <>
        <path
            fill="#ECFDF3"
            stroke="#DCFAE6"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.667"
            d="M10.333 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 000 16.666z"
        ></path>
        <path
            fill="#079455"
            stroke="#079455"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.667"
            d="M10.332 13a3 3 0 100-6 3 3 0 000 6z"
        ></path>
    </>
)

export const NotActiveIcon = generateIcon(
    'notActiveIcon',
    <>
        <path
            fill="#ECFDF3"
            stroke="#DCFAE6"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.667"
            d="M10.333 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 000 16.666z"
        ></path>
        <path
            fill="#079455"
            stroke="#079455"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.667"
            d="M10.332 13a3 3 0 100-6 3 3 0 000 6z"
        ></path>
    </>
)

export const NotResultIcon = generateIcon(
    'notResultIcon',
    <g fill="none" fillRule="evenodd" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
        <g stroke="#424242" strokeWidth="2" transform="translate(1 1)">
            <path d="M16 7.5V4.8c0-1.68 0-2.52-.327-3.162A3 3 0 0014.362.327C13.72 0 12.88 0 11.2 0H4.8C3.12 0 2.28 0 1.638.327A3 3 0 00.327 1.638C0 2.28 0 3.12 0 4.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 001.311 1.311C2.28 20 3.12 20 4.8 20H10m0-11H4m2 4H4m8-8H4m8.5 8.002a2.249 2.249 0 014.37.75c0 1.499-2.25 2.248-2.25 2.248"></path>
        </g>
    </g>
)

export const LogoutIcon = generateIcon(
    'logoutIcon',
    <g fill="none" fillRule="evenodd" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
        <g stroke="#772917" strokeWidth="1.667" transform="translate(1 1)">
            <path d="M11.333 12.167L15.5 8m0 0l-4.167-4.167M15.5 8h-10m0-7.5h-1C3.1.5 2.4.5 1.865.772A2.5 2.5 0 00.772 1.865C.5 2.4.5 3.1.5 4.5v7c0 1.4 0 2.1.272 2.635a2.5 2.5 0 001.093 1.092C2.4 15.5 3.1 15.5 4.5 15.5h1"></path>
        </g>
    </g>
)

export const AddIcon = generateIcon(
    'addIcon',
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
            <rect width="24" height="24" x="0" y="0" fillRule="nonzero" rx="12"></rect>
            <path
                stroke="currentColor"
                strokeDasharray="1"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0z"
            ></path>
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.333"
                d="M11.999 7.333v9.334M7.332 12h9.333"
            ></path>
        </g>
    </g>
)
export const HomeIcon = generateIcon(
    'addIcon',
    <g fill="none" fillRule="evenodd" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
        <g stroke="currentColor" strokeWidth="2" transform="translate(2 3)">
            <path d="M7 19v-7.4c0-.56 0-.84.109-1.054a1 1 0 01.437-.437C7.76 10 8.04 10 8.6 10h2.8c.56 0 .84 0 1.054.109a1 1 0 01.437.437C13 10.76 13 11.04 13 11.6V19H7zM0 7.5L9.04.72c.344-.258.516-.387.705-.437a1 1 0 01.51 0c.189.05.36.179.705.437L20 7.5H0zM2 6v9.8c0 1.12 0 1.68.218 2.108a2 2 0 00.874.874C3.52 19 4.08 19 5.2 19h9.6c1.12 0 1.68 0 2.108-.218a2 2 0 00.874-.874C18 17.48 18 16.92 18 15.8V6l-6.08-4.56c-.688-.516-1.033-.775-1.41-.874a2 2 0 00-1.02 0c-.377.1-.722.358-1.41.874L2 6z"></path>
        </g>
    </g>
)
