import styled, {css, DefaultTheme} from 'styled-components'
import {NavLink} from 'react-router-dom'

type StyledNavLinkProps = {
    size: 'sm' | 'md' | 'lg'
    variant: 'ghost'
}

export const StyledNavLink = styled(NavLink)<StyledNavLinkProps>`
    ${({theme: {spacing}}) => css`
        position: relative;
        display: flex;
        align-items: center;
        gap: ${spacing * 3}px;
        width: auto;
        transition: ease-in-out 200ms;
        cursor: pointer;
        font-weight: 600;
        font-size: 16px;
        height: 48px;
        text-transform: capitalize;

        & svg,
        & img {
            width: 20px;
            filter: none;
        }
    `}

    ${({variant, theme}) => ColorStyles(theme)[variant]};
    ${({size, theme}) => SizeStyles(theme)[size]};

    /* Cambia colore a bianco su hover */
    &:hover svg,
    &:hover img {
        filter: brightness(0) invert(1);
    }

    /* Cambia colore a bianco quando è attivo */
    &.active svg,
    &.active img {
        filter: brightness(0) invert(1);
    }
`

const ColorStyles = ({palette}: DefaultTheme) => ({
    ghost: css`
        box-sizing: border-box;
        background: transparent;
        color: ${palette.primary['700']};
        &:not(:disabled):hover {
            background: ${palette.primary['700']};
            color: white;
            border-left: 2px solid white;
        }

        &:not(:disabled).active {
            border-left: 2px solid #ffffff;
            background: ${palette.primary['700']};
            font-weight: 600;
            color: white;
        }

        &:disabled {
            background: ${palette.neutral.white};
            color: ${palette.neutral['300']};
            cursor: not-allowed;
        }
    `
})

const SizeStyles = ({spacing, typography}: DefaultTheme) => ({
    sm: css`
        height: 36px;
        padding: ${spacing * 4}px ${spacing * 3.5}px;
        ${typography.textSm}
    `,
    md: css`
        height: 44px;
        padding: ${spacing * 2.5}px ${spacing * 4.5}px;
        ${typography.textMd}
    `,
    lg: css`
        height: 60px;
        padding: ${spacing * 4}px ${spacing * 7}px;
        ${typography.textLg}
    `
})
