import styled, {css, DefaultTheme} from 'styled-components'

export const StyledContainer = styled.div`
    ${({theme: {spacing}}: {theme: DefaultTheme}) => css`
        display: flex;
        flex-flow: column;
        gap: ${spacing * 6}px;
        & > .infinite_cta_container {
            position: fixed;
            bottom: 40px;
            right: 48px;
            padding: ${spacing * 2}px;
        }
    `}
`
