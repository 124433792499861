import {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {useMutation, useQueryClient, InfiniteData, useQuery} from '@tanstack/react-query'
import dayjs from 'dayjs'
import toast from 'react-hot-toast'
import {DeleteModal} from '@/components/commons/delete-modal/DeleteModal'
import {EmptyResult} from '@/components/commons/empty-result/EmptyResult'
import Table from '@/components/commons/table/Table'
import {TableDataCell} from '@/components/commons/table/table-data-cell/TableDataCell'
import {Trash01Icon, PlusIcon, SearchLgIcon} from '@/components/ui/icon/Icon'
import {ContentResponse, ContentsResponse} from '@/features/contents/ContentsSchema'
import {InputText} from '@/components/commons/input-text/InputText'
import {ButtonLink} from '@/components/ui/button-link/ButtonLink'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {deliveryTypeRemap} from '@/utilities/helpers'
import {errorHandler} from '@/utilities/genericErrorHandler'
import {deleteContentDetail} from '@/features/contents/contents'
import {AvaiableChip, StyledRemainingItems, VisibilityChip} from './style'
import {ContainerName} from '@/utilities/constants/commons'
import {getUser} from '@/features/delivery/services/delivery'

interface ContentsTableProps {
    data: InfiniteData<ContentsResponse> | undefined
    isFetching: boolean
    isAscOrder: boolean
    setIsAscOrder: (e: boolean) => void
    detailLink: string
    containerId?: string | null
    isFetchingNextPage: boolean
    fetchNextPage: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const ContentsTable = ({
    data,
    isFetching,
    isAscOrder,
    setIsAscOrder,
    detailLink,
    containerId,
    isFetchingNextPage,
    fetchNextPage
}: ContentsTableProps) => {
    const {t} = useTranslation()
    const queryClient = useQueryClient()
    const [deleteContent, setDeleteContent] = useState<ContentResponse | undefined>(undefined)
    // const [qrModal, setQrModal] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [filteredData, setFilteredData] = useState<ContentResponse[]>([])
    const searchRef = useRef<HTMLInputElement | null>(null)

    useEffect(() => {
        const handleSearch = () => {
            setSearchValue(searchRef.current?.value || '')
        }

        const searchInput = searchRef.current
        searchInput?.addEventListener('input', handleSearch)

        return () => {
            searchInput?.removeEventListener('input', handleSearch)
        }
    }, [])

    const {data: userData} = useQuery({
        queryKey: ['users'],
        queryFn: () => getUser()
    })
    console.log(userData)

    useEffect(() => {
        if (data?.pages) {
            const allData = data.pages.flatMap(page => page.data)
            const filtered = allData.filter(item =>
                item.contentData[0]?.title.toLowerCase().includes(searchValue.toLowerCase())
            )
            const sorted = filtered.sort((a, b) => {
                if (isAscOrder) {
                    return a.createdAt.localeCompare(b.createdAt)
                } else {
                    return b.createdAt.localeCompare(a.createdAt)
                }
            })
            setFilteredData(sorted)
        }
    }, [data, searchValue, isAscOrder])

    const deleteMutation = useMutation({
        retry: false,
        mutationKey: ['delete-profile'],
        mutationFn: (id: number) => deleteContentDetail(id),
        onSuccess: () => {
            toast.success('Contenuto eliminato con successo')
            setDeleteContent(undefined)
            queryClient.invalidateQueries({queryKey: ['contents-list']})
            window.location.replace(`/${detailLink}?id=${containerId}`)
            window.history.pushState(null, '', `/${detailLink}?id=${containerId}`)
        },
        onError: e => {
            errorHandler(e)
        }
    })

    return (
        <>
            <Flexbox gap={2} align="center" justify="space-between">
                <InputText
                    ref={searchRef}
                    type={'text'}
                    placeholder={t('table:search')}
                    typeIcon={<SearchLgIcon size={18} />}
                    inputSize={'sm'}
                    width={320}
                />

                <ButtonLink
                    variant={'primary'}
                    size={'md'}
                    to={`/${detailLink}/${ContainerName.newContent}?containerId=${containerId}`}
                >
                    <PlusIcon />
                    {t('table:create')}
                </ButtonLink>
            </Flexbox>
            <Table
                onChangePage={fetchNextPage}
                isChangingPage={isFetchingNextPage}
                data={filteredData || []}
                isLoading={isFetching}
                sorter={{
                    orderBy: 'date',
                    orderDirection: isAscOrder ? 'asc' : 'desc',
                    onSort: () => setIsAscOrder(!isAscOrder)
                }}
                columns={[
                    {
                        label: t('table:title'),
                        width: '31%',
                        cellRender: item => (
                            <TableDataCell title={'Title'}>
                                <Link
                                    to={`/${detailLink}/${item?.contentData[0]?.title?.toLowerCase().replace(/\s/g, '-')}?id=${item.id}&containerId=${containerId}`}
                                    style={{color: '#B93815', fontWeight: '600'}}
                                >
                                    {item?.contentData[0]?.title}
                                </Link>
                            </TableDataCell>
                        )
                    },
                    {
                        label: t('table:visibility'),
                        width: '16%',
                        cellRender: item => (
                            <TableDataCell title={'Visibility'}>
                                <VisibilityChip $isVisible={item.isVisible === 1}>
                                    <span /> {item.isVisible === 1 ? t('table:public') : t('table:private')}
                                </VisibilityChip>
                            </TableDataCell>
                        )
                    },
                    // {
                    //     label: t('table:qr'),
                    //     width: '12%',
                    //     cellRender: item => (
                    //         <TableDataCell title={'Qr'}>
                    //             {item.qrCode ? (
                    //                 <QrIcon onClick={() => setQrModal(true)} style={{cursor: 'pointer'}} />
                    //             ) : (
                    //                 <NoQrIcon />
                    //             )}
                    //             {qrModal && (
                    //                 <QrModal
                    //                     onClose={() => setQrModal(false)}
                    //                     title={t('modal:qr:title')}
                    //                     paragraph={t('modal:qr:description')}
                    //                     qrCode={item.qrCode}
                    //                 />
                    //             )}
                    //         </TableDataCell>
                    //     )
                    // },
                    {
                        label: t('table:available'),
                        width: '28%',
                        cellRender: item => {
                            const maxChipsToShow = 2
                            const deliverySystems = item.deliverySystems
                            const remainingChipsCount = deliverySystems.length - maxChipsToShow

                            return (
                                <TableDataCell
                                    size="sm"
                                    title={'Available'}
                                    style={{width: '100%', display: 'flex', flexFlow: 'row wrap', gap: '8px'}}
                                >
                                    {deliverySystems.slice(0, maxChipsToShow).map(i => (
                                        <AvaiableChip $type={deliveryTypeRemap(i.type)} key={i.id}>
                                            {deliveryTypeRemap(i.type)}
                                        </AvaiableChip>
                                    ))}
                                    {remainingChipsCount > 0 && (
                                        <StyledRemainingItems key="remaining">
                                            +{remainingChipsCount}
                                        </StyledRemainingItems>
                                    )}
                                </TableDataCell>
                            )
                        }
                    },
                    {
                        label: t('table:date'),
                        width: '18%',
                        sortName: 'date',
                        cellRender: item => (
                            <TableDataCell title={'Created'}>
                                {dayjs(item.createdAt).format('DD/MM/YYYY')}
                            </TableDataCell>
                        )
                    },
                    {
                        label: '',
                        width: '7%',
                        cellRender: item => (
                            <TableDataCell title={'delete'}>
                                <Trash01Icon onClick={() => setDeleteContent(item)} style={{cursor: 'pointer'}} />
                            </TableDataCell>
                        )
                    }
                ]}
                emptyStateComponent={<EmptyResult title={t('empty:title')} description={t('empty:description')} />}
                emptySearchStateComponent={
                    <EmptyResult title={t('empty:title')} description={t('empty:description')} />
                }
            />
            {deleteContent && (
                <DeleteModal
                    isLoading={deleteMutation.isPending}
                    onDelete={() => {
                        deleteMutation.mutate(deleteContent.id)
                    }}
                    onClose={() => setDeleteContent(undefined)}
                    title={`${t('modal:delete:title')}`}
                    paragraph={t('modal:delete:description')}
                />
            )}
        </>
    )
}
