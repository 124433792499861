import styled, {css} from 'styled-components'
import {InputWrapper} from '@/components/ui/input-wrapper/InputWrapper'
import {CSSProperties} from 'react'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledInputFile = styled(Flexbox)<{$width: CSSProperties['width']}>(
    ({$width, theme: {spacing}}) => css`
        width: ${$width};
        & > .presentation {
            width: 100%;
        }
        & .description_container {
            display: flex;
            flex-flow: column;
            gap: ${spacing * 2}px;
            white-space: break-spaces;
        }
    `
)

export const StyledImageWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 4}px;
    `
)

export const StyledImageContainer = styled.div(
    () => css`
        position: relative;
        height: 96px;
        & > img {
            height: 96px;
        }
        & > .remove_cta {
            position: absolute;
            top: 0;
            right: 0;
        }
    `
)

export const StyledInputWrapper = styled(InputWrapper)(
    ({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 4}px;
        border: 1px solid transparent;
        flex: 1;
    `
)
