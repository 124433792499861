import {ContentReferencesType, ContentResponse, ContentsResponse} from '@/features/contents/ContentsSchema'
import {AvaiableOnType, ContainerName} from './constants/commons'
import {DeliverySystemType} from '@/features/delivery/services/DeliverySchema'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)

type ReactSelect = {
    label: string
    value: string | number
}
export const retrieveSelectSingleValue = (options: ReactSelect[], value: string) => {
    return options.find(option => option.value.toString() == value)
}

export const retrieveSelectMultiValues = (options: ReactSelect[], values: [string]) => {
    return options.filter(option => values.find(value => value.toString() == option.value.toString()))
}

export const getInitials = (name: string) => {
    return name.match(/\b\w/g) || []
}

export const megabytesToBytes = (megabytes: number) => megabytes * 1_000_000

/**
 * Function that throws an error, it's useful to prevent impossible states in the application.
 * Example: const id = params.id ?? raise('No id provided'), in this case id is a string instead of string | undefined
 * @param error
 */
export const raise = (error: string): never => {
    throw new Error(error)
}

export const debounce = <T extends (...args: Parameters<T>) => ReturnType<T>>(
    callback: T,
    delay: number
): ((...args: Parameters<T>) => void) => {
    let timeout: ReturnType<typeof setTimeout>

    return (...args: Parameters<T>) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            callback(...args)
        }, delay)
    }
}

export const deliveryTypeRemap = (type: string) => {
    switch (type) {
        case 'mobile_app':
            return AvaiableOnType.app
        case 'totem_catalogue_room':
            return AvaiableOnType.catalogs
        case 'totem_museum_itinerary':
            return AvaiableOnType.museal
        case 'totem_monumental_salon':
            return AvaiableOnType.monumental
        default:
            return AvaiableOnType.catalogs
    }
}

export const remapDeliverySystems = (data?: DeliverySystemType[]) => {
    return (
        data?.map(({id, type}) => ({
            value: id.toString(),
            label: deliveryTypeRemap(type)
        })) || []
    )
}

export const remapContentsSystems = (deliverySystemId: number, data?: ContentsResponse) => {
    const filteredData = data?.data.filter(item =>
        item?.deliverySystems.some(subItem => subItem.id === deliverySystemId)
    )
    return (
        filteredData?.map(({contentData}) => ({
            value: contentData[0]?.contentId?.toString(),
            label: contentData[0]?.title
        })) || []
    )
}

export const findContentBlockTitles = (
    deliverySystemId: number,
    data?: ContentsResponse,
    recommendations?: ContentReferencesType
): {value: string; label: string}[] | undefined => {
    if (!recommendations || !data) return undefined

    const filteredRecommendations = recommendations.filter(item => item.deliverySystemId === deliverySystemId)

    const result = filteredRecommendations
        .map(recommendation => {
            const relatedContent = data.data.find(
                (item: ContentResponse) => item.id === recommendation.relatedContentId
            )
            if (relatedContent) {
                const contentData = relatedContent.contentData.find(
                    block => block.contentId === recommendation.relatedContentId
                )

                if (contentData) {
                    return {
                        label: contentData.title,
                        value: contentData.contentId.toString()
                    }
                }
            }
            return undefined
        })
        .filter((item): item is {value: string; label: string} => item !== null)

    return result.length > 0 ? result : undefined
}

export const remapReferences = (deliverySystemId: number, data?: ContentReferencesType) => {
    //TODO ADD TITLE IN BE RESPONSE
    return (
        data
            ?.filter(i => i.deliverySystemId === deliverySystemId)
            ?.map(({relatedContentId}) => ({
                value: relatedContentId.toString(),
                label: relatedContentId.toString()
            })) || []
    )
}

export const scrollToBlock = (element: HTMLElement | null) => {
    if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start'})
    }
}

export const convertInMB = (bytes: number) => {
    const kb = bytes / 1024
    const mb = kb / 1024
    return Number(mb.toFixed(2))
}

export const useUnsavedChangesWarning = (hasUnsavedChanges: boolean) => {
    const [showWarning, setShowWarning] = useState(false)
    const [nextLocation, setNextLocation] = useState<string | null>(null)
    const [confirmedNavigation, setConfirmedNavigation] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            if (hasUnsavedChanges) {
                e.preventDefault()
                e.returnValue = ''
                return ''
            }
        }

        window.addEventListener('beforeunload', handleBeforeUnload)

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [hasUnsavedChanges])

    const handleBlockedNavigation = (location: string) => {
        if (!confirmedNavigation && hasUnsavedChanges) {
            setShowWarning(true)
            setNextLocation(location)
            return false
        }
        return true
    }

    const confirmNavigation = () => {
        setShowWarning(false)
        setConfirmedNavigation(true)
        if (nextLocation) {
            navigate(nextLocation)
        }
    }

    const cancelNavigation = () => {
        setShowWarning(false)
    }

    return {
        showWarning,
        confirmNavigation,
        cancelNavigation,
        handleBlockedNavigation
    }
}

export const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setSearchParams: (value: string) => void
) => {
    if (event.target.value.length >= 3) {
        setSearchParams(event.target.value)
    }
    if (event.target.value.length === 0) {
        setSearchParams('')
    }
}

export const containersRemap = (container?: string) => {
    switch (container) {
        case '1':
            return ContainerName.orientation
        case '2':
            return ContainerName.characters
        case '3':
            return ContainerName.history
        case '4':
            return ContainerName.spaces
        case '5':
            return ContainerName.bibliographicFunds
        case '6':
            return ContainerName.numismatics
        case '7':
            return ContainerName.archeology
        case '8':
            return ContainerName.scientificInstruments
        default:
            return ContainerName.orientation
    }
}
