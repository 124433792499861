import styled, {DefaultTheme, css} from 'styled-components'

type StyledDataCellProps = {
    theme: DefaultTheme
    $isBold?: boolean
    $isWarn?: boolean
    $isDanger?: boolean
    size: 'sm' | 'xs' | 'md'
}

export const StyledTableDataCell = styled.div<StyledDataCellProps>`
    ${({theme: {palette, typography}, $isBold, $isWarn, $isDanger, size}) => css`
        font-weight: ${$isBold ? '700' : '400'};
        ${size === 'sm' ? typography.textSm : typography.textXs}
        color: ${$isWarn
            ? palette.danger[600]
            : $isBold
              ? palette.neutral[900]
              : $isDanger
                ? palette.warning[500]
                : palette.neutral[600]};
        p {
            text-overflow: ellipsis;
            display: block;
            overflow: hidden;
            white-space: nowrap;
        }
        a {
            text-overflow: ellipsis;
            display: block;
            overflow: hidden;
            white-space: nowrap;
            color: inherit;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    `}
    ${({size, theme}) => SizeStyles({theme})[size]};
`

type SizesProps = {
    theme: DefaultTheme
}

const SizeStyles = ({theme: {typography}}: SizesProps) => ({
    xs: css`
        ${typography.textXs}
    `,
    sm: css`
        ${typography.textSm}
    `,
    md: css`
        ${typography.textMd}
    `
})
