import React from 'react'
import {Button} from '@components/ui/button/Button.tsx'
import {Container} from '@components/ui/container/Container.tsx'
import {StyledBackCta, StyledLoginBox, StyledLoginForm, StyledLoginWrapper} from '@pages/login/style.ts'
import {useTranslation} from 'react-i18next'
import Key from '@assets/images/key_icon.svg'

import {zodResolver} from '@hookform/resolvers/zod'
import {useForm} from 'react-hook-form'
import {InputText} from '@/components/commons/input-text/InputText'
import {ForgotRequest, ForgotRequestSchema} from '@/features/auth/services/LoginSchema'
import {useNavigate} from 'react-router-dom'
import {userRecoveryPassword} from '@/features/auth/services/auth.http'
import {useMutation} from '@tanstack/react-query'
import {errorHandler} from '@/utilities/genericErrorHandler'
import toast from 'react-hot-toast'
import {ArrowRightIcon} from '@/components/ui/icon/Icon'

export const ForgotPassword: React.FC = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const {
        handleSubmit,
        register,
        formState: {errors, touchedFields}
    } = useForm<ForgotRequest>({
        defaultValues: {
            email: ''
        },
        resolver: zodResolver(ForgotRequestSchema),
        mode: 'onBlur'
    })

    const mutation = useMutation({
        retry: false,
        mutationKey: ['recovery'],
        mutationFn: (data: ForgotRequest) => userRecoveryPassword(data),
        onSuccess: () => {
            toast.success('A breve riceverai una mail per il recupero della password')
        },
        onError: e => {
            errorHandler(e)
        }
    })

    const onSubmit = (data: ForgotRequest) => {
        const {email} = data
        mutation.mutate({
            email: email
        })
    }

    return (
        <Container>
            <StyledLoginWrapper>
                <StyledLoginBox>
                    <img src={Key} alt={'logo'} height={56} />
                    <h4>{t('forgot:title')}</h4>
                    <p>{t('forgot:subtitle')}</p>
                    <StyledLoginForm onSubmit={handleSubmit(onSubmit)}>
                        <InputText
                            label={t('forgot:form:email')}
                            type={'text'}
                            touched={touchedFields.email}
                            errorMessage={errors?.email?.message || ''}
                            placeholder={t('forgot:form:email')}
                            {...register('email')}
                            width={'360px'}
                            autoComplete={'off'}
                            name={'email'}
                        />

                        <Button type="submit" name="Submit" variant={'primary'} size={'lg'}>
                            {t('forgot:cta')}
                        </Button>
                        <StyledBackCta>
                            <ArrowRightIcon width={20} fill="#475467" />
                            <span onClick={() => navigate('/login')}>{t('forgot:to_login')}</span>
                        </StyledBackCta>
                    </StyledLoginForm>
                </StyledLoginBox>
            </StyledLoginWrapper>
        </Container>
    )
}

ForgotPassword.displayName = 'ForgotPassword'
