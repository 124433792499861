import {Container} from '@components/ui/container/Container.tsx'
import {PageHero} from '@components/commons/page-hero/PageHero.tsx'
import {useQuery} from '@tanstack/react-query'
import {useEffect} from 'react'
import {errorHandler} from '@/utilities/genericErrorHandler'
import {DeliveryResponse} from '@/features/delivery/services/DeliverySchema'
import {getDelivery, getUser} from '@/features/delivery/services/delivery'
import {
    DashboardCardContainer,
    DashboardCardsContainer,
    DashboardCardsInfoContainer,
    DashboardCardsItemsContainer
} from './styles'
import room_catalogs from '@assets/images/room_catalogs.png'
import room_hall from '@assets/images/room_hall.png'
import room_museum from '@assets/images/room_museum.png'
import placeholder from '@assets/images/placeholder.jpg'
import {useTranslation} from 'react-i18next'
import {deliveryTypeRemap} from '@/utilities/helpers'

export const Dashboard = () => {
    const {t} = useTranslation()

    const {data: userData} = useQuery({
        queryKey: ['users'],
        queryFn: () => getUser()
    })
    console.log(userData)

    const {data, error, ...props} = useQuery<DeliveryResponse>({
        retry: false,
        queryKey: ['get-delivery'],
        queryFn: () => getDelivery()
    })
    console.log(props.failureReason)
    useEffect(() => {
        if (error) {
            errorHandler(error)
        }
    }, [error])

    const images = [room_catalogs, placeholder, room_museum, room_hall]

    return (
        <Container>
            <PageHero title={t('dashboard:title')} subtitle={''} />
            <DashboardCardsContainer>
                {data?.data?.map((item, index) => {
                    const image = images[index]
                    return (
                        <DashboardCardContainer key={item.id}>
                            <img
                                style={{width: '318px', height: '187px', borderRadius: '8px', objectFit: 'cover'}}
                                src={image}
                                alt="copertina"
                            />
                            <h2>{deliveryTypeRemap(item.type)}</h2>
                            <DashboardCardsInfoContainer>
                                <DashboardCardsItemsContainer>
                                    <p>Contenuti: {item.contentsCount}</p>
                                    {/* <DashboardStatusContainer>
                                        <ActiveIcon width={20} height={20} />
                                        <span>{t('dashboard:card:active')}</span>
                                    </DashboardStatusContainer> */}
                                </DashboardCardsItemsContainer>
                                {/* <DashboardCardsItemsContainer>
                                    <span>
                                        {t('dashboard:card:created')} {dayjs(item.createdAt).format('DD/MM/YYYY')}
                                    </span>
                                    <span>
                                        {t('dashboard:card:last')} {dayjs(item.lastNewContent).format('DD/MM/YYYY')}
                                    </span>
                                </DashboardCardsItemsContainer> */}
                            </DashboardCardsInfoContainer>
                        </DashboardCardContainer>
                    )
                })}
            </DashboardCardsContainer>
        </Container>
    )
}

Dashboard.displayName = 'Dashboard'
