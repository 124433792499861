import ReactSelect, {ActionMeta, MultiValue, SingleValue} from 'react-select'
import CreatableSelect from 'react-select/creatable'
import {customStyles, StyledContainer} from './style.ts'
import {useTheme} from 'styled-components'
import {Label} from '@components/ui/label/Label.tsx'
import {ReactNode, forwardRef, useCallback} from 'react'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText.tsx'
import {customComponents} from '@components/commons/select/commons.tsx'
import {DefaultNamespace} from 'i18next'

export type SelectValue = {
    label: string
    value: string
}

export interface SelectProps {
    className?: string
    name?: string
    options: SelectValue[]
    label?: string | DefaultNamespace
    placeholder?: string | DefaultNamespace
    helpText?: string | DefaultNamespace
    readOnly?: boolean
    defaultValue?: SelectValue | []
    isSearchable?: boolean
    isClearable?: boolean
    isCreatable?: boolean
    formatCreateLabel?: (value: string) => ReactNode
    addOptionMessage?: string
    maxItems?: number
    isMulti?: boolean
    closeMenuOnSelect?: boolean
    disabled?: boolean
    hideSelectedOptions?: boolean
    size?: 'medium' | 'large'
    setSearch?: (e: string) => void
    /**
     * How to use the onChange based on controlled or uncontrolled select:
     *
     * uncontrolled: onChange={(event) => console.log(event)}
     * controlled multi: onChange={(newValue) => {onChange(newValue as SelectValue[])}}
     * controlled single: onChange={(newValue) => {onChange([newValue] as SelectValue[])}}
     */
    onChange?: (
        newValue: SingleValue<SelectValue> | MultiValue<SelectValue> | SelectValue[],
        actionMeta: ActionMeta<SelectValue>
    ) => void
    value?: SelectValue | SelectValue[]
    errorMessage?: string | DefaultNamespace
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Select = forwardRef<any, SelectProps>(
    (
        {
            className,
            name,
            options = [],
            label,
            placeholder,
            helpText,
            defaultValue,
            isSearchable = false,
            isCreatable = false,
            addOptionMessage = '',
            maxItems = 100,
            isMulti = false,
            closeMenuOnSelect = !isMulti,
            hideSelectedOptions = true,
            disabled,
            onChange,
            errorMessage,
            setSearch,
            ...rest
        },
        ref
    ) => {
        const theme = useTheme()
        // Label for new item creation
        const createLabel = useCallback(
            (value: string) => (
                <span style={{fontSize: 14}}>
                    {addOptionMessage}
                    <span>{value}</span>
                </span>
            ),
            [addOptionMessage]
        )

        const selectProps = {
            options,
            name,
            closeMenuOnSelect,
            hideSelectedOptions,
            isSearchable,
            isCreatable,
            isMulti,
            isDisabled: rest.readOnly || disabled,
            classNamePrefix: isCreatable ? 'creatable_select' : 'select',
            placeholder,
            createLabel,
            disabled,
            maxItems,
            defaultValue,
            onChange,
            setSearch,
            ...rest
        }

        const selectComponentProps = {
            formatCreateLabel: createLabel,
            components: customComponents,
            ...selectProps,
            styles: customStyles({theme, isError: !!errorMessage})
        }

        return (
            <StyledContainer className={className}>
                {label && <Label htmlFor={name}>{label}</Label>}
                {isCreatable ? (
                    <CreatableSelect {...selectComponentProps} />
                ) : (
                    <ReactSelect
                        {...selectComponentProps}
                        id={name}
                        ref={ref}
                        onInputChange={(inputValue: string) => {
                            setSearch && setSearch(inputValue)
                        }}
                    />
                )}
                <InputHelpText helpText={helpText} error={errorMessage} />
            </StyledContainer>
        )
    }
)

export default Select

Select.displayName = 'Select'
