import {z} from 'zod'

export const SelectItem = z.object({
    label: z.string(),
    value: z.string()
})

export const MetaSchema = z.object({
    total: z.number(),
    per_page: z.number(),
    current_page: z.number(),
    last_page: z.number(),
    first_page: z.number(),
    first_page_url: z.string(),
    last_page_url: z.string(),
    next_page_url: z.string().nullable(),
    previous_page_url: z.string().nullable()
})

export type MetaType = z.infer<typeof MetaSchema>
