import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledLoginWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`

export const StyledLoginBox = styled(Flexbox)`
    ${({theme: {palette, spacing}}) => css`
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 360px;

        & > h4 {
            font-size: 30px;
            font-family: 'Playfair Display';
            font-weight: 600;
            color: ${palette.neutral['900']};
            text-align: center;
            margin-bottom: ${spacing * 2}px;
        }

        & > p {
            font-size: 16px;
            font-weight: 400;
            margin-bottom: ${spacing * 12}px;
            text-align: center;
        }

        & img {
            margin: 0 auto ${spacing * 6}px;
        }
    `}
`

export const StyledLoginForm = styled.form(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-flow: column;
        gap: ${spacing * 6}px;
        align-items: center;
        width: 360px;
        & > p {
            width: 100%;
            text-align: right;
            color: #b93815;
            margin: 8px 0px;
            cursor: pointer;
        }
        & > span {
            width: 100%;
            text-align: center;
            color: #475467;
            margin: 8px 0px;
            cursor: pointer;
        }
        & button {
            width: 360px;
        }
    `
)

export const StyledBackCta = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing}px;
        & > svg {
            transform: rotate(180deg);
        }
        & > span {
            height: 20px;
            color: #475467;
        }
    `
)
