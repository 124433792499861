import {z} from 'zod'
import {MetaSchema, SelectItem} from '../commons/Commons'

export const ProfileFormSchema = z.object({
    email: z
        .string()
        .refine(
            value => {
                if (value === null || value === '') {
                    return true
                }
                const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                return value && emailRegex.test(value)
            },
            {
                message: 'Invalid email'
            }
        )
        .optional(),
    firstName: z.string(),
    lastName: z.string(),
    role: SelectItem.optional(),
    isActive: SelectItem.optional(),
    password: z.string().min(6).max(20).optional(),
    passwordConfirmation: z.string().min(6).max(20).optional()
})
export type ProfileFormType = z.infer<typeof ProfileFormSchema>

export const ProfileCreateRequestSchema = z.object({
    id: z.number().optional(),
    email: z.string().optional(),
    firstName: z.string(),
    lastName: z.string(),
    role: z.string(),
    isActive: z.boolean().optional(),
    password: z.string().min(6).max(20).optional(),
    passwordConfirmation: z.string().min(6).max(20).optional()
})
export type ProfileCreateRequest = z.infer<typeof ProfileCreateRequestSchema>

export const ProfileRequestSchema = z.object({
    id: z.number(),
    email: z.string().optional(),
    firstName: z.string(),
    lastName: z.string(),
    role: z.string(),
    isActive: z.boolean().optional()
})
export type ProfileRequest = z.infer<typeof ProfileRequestSchema>

export const ProfileResponseSchema = z.object({
    id: z.number(),
    email: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    role: z.string(),
    isActive: z.boolean(),
    lastLoginAt: z.string().nullable(),
    createdAt: z.string(),
    updatedAt: z.string()
})

export type ProfileResponse = z.infer<typeof ProfileResponseSchema>

export const UsersRequestSchema = z.object({
    meta: MetaSchema,
    data: z.array(ProfileResponseSchema)
})

export type UsersRequest = z.infer<typeof UsersRequestSchema>
