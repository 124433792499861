import axios from '@/axiosInstance.ts'
import {DeliveryResponse, DeliverySchema} from './DeliverySchema.ts'

export const getDelivery = async (): Promise<DeliveryResponse> => {
    const response = await axios.get(`/delivery-systems/`)
    return DeliverySchema.parse(response.data)
}

export const getUser = async () => {
    const response = await axios.get(`users/profile`)
    return response.data
}
