import React, {useState} from 'react'
import {Button} from '@components/ui/button/Button.tsx'
import {Container} from '@components/ui/container/Container.tsx'
import {StyledLoginBox, StyledLoginForm, StyledLoginWrapper} from '@pages/login/style.ts'
import {useTranslation} from 'react-i18next'
import Key from '@assets/images/key_icon.svg'

import {zodResolver} from '@hookform/resolvers/zod'
import {useMutation} from '@tanstack/react-query'
import {useForm} from 'react-hook-form'
import {InputText} from '@/components/commons/input-text/InputText'
import {userChangePassword} from '@/features/auth/services/auth.http'
import {errorHandler} from '@/utilities/genericErrorHandler'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {ChangeRequest, ChangeRequestSchema} from '@/features/auth/services/LoginSchema'
import {useLocation, useNavigate} from 'react-router-dom'
import toast from 'react-hot-toast'

export const ChangePassword: React.FC = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const token = searchParams.get('token')

    const [login, setLogin] = useState(false)

    const {
        handleSubmit,
        register,
        formState: {errors, touchedFields}
    } = useForm<ChangeRequest>({
        defaultValues: {
            password: '',
            passwordConfirmation: ''
        },
        resolver: zodResolver(ChangeRequestSchema),
        mode: 'onBlur'
    })

    const mutation = useMutation({
        retry: false,
        mutationKey: ['change'],
        mutationFn: (data: ChangeRequest) => userChangePassword(data, token || ''),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onSuccess: (data: any) => {
            console.log(data)
            toast.success('Password aggiornata con successo')
            setLogin(true)
        },
        onError: e => {
            errorHandler(e)
        }
    })

    const onSubmit = (data: ChangeRequest) => {
        const {password, passwordConfirmation} = data

        if (data.password !== data.passwordConfirmation) {
            toast.error('Le password non coincidono')
        } else {
            mutation.mutate({
                password: password,
                passwordConfirmation: passwordConfirmation
            })
        }
    }

    return (
        <Container>
            <StyledLoginWrapper>
                <StyledLoginBox>
                    <img src={Key} alt={'logo'} height={56} />
                    <h4>{t('change:title')}</h4>
                    <p>{t('change:subtitle')}</p>
                    <StyledLoginForm onSubmit={handleSubmit(onSubmit)}>
                        <InputText
                            label={t('change:form:password')}
                            type={'password'}
                            touched={touchedFields.password}
                            errorMessage={errors?.password?.message || ''}
                            placeholder={t('change:form:password')}
                            {...register('password')}
                            visibilityToggle={true}
                            width={'360px'}
                            autoComplete={'off'}
                            name={'password'}
                        />

                        <InputText
                            label={t('change:form:passwordConfirmation')}
                            type={'password'}
                            touched={touchedFields.passwordConfirmation}
                            errorMessage={errors?.passwordConfirmation?.message || ''}
                            placeholder={t('change:form:passwordConfirmation')}
                            {...register('passwordConfirmation')}
                            visibilityToggle={true}
                            width={'360px'}
                            autoComplete={'off'}
                            name={'passwordConfirmation'}
                        />
                        {!login ? (
                            <Button type="submit" name="Submit" variant={'primary'} size={'lg'}>
                                {!mutation.isPending ? t('change:confirm') : <Spinner size={16} />}
                            </Button>
                        ) : (
                            <Button
                                type="button"
                                name="toHome"
                                variant={'primary'}
                                size={'lg'}
                                onClick={() => navigate('/login')}
                            >
                                {t('change:to_login')}
                            </Button>
                        )}
                    </StyledLoginForm>
                </StyledLoginBox>
            </StyledLoginWrapper>
        </Container>
    )
}

ChangePassword.displayName = 'ChangePassword'
