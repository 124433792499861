import Logo from '@assets/images/Logo.svg'
import {NavLink} from '@components/ui/navlink/NavLink.tsx'
import {useState} from 'react'
import {useAuthStore} from '@/features/auth/store/store.ts'
import {logout} from '@/features/auth/store/actions.ts'
import {AvatarContainer, AvatarNameContainer, MenuItems, NavbarGrid, NavbarWrapper} from './style'
import {LogoutModal} from '../logout-modal/LogoutModal'
import {useNavigate} from 'react-router-dom'
import {LogoutIcon} from '@/components/ui/icon/uiIcons'
import {useTranslation} from 'react-i18next'
import {RoleType} from '@/utilities/constants/commons'
import homeIcon from '../../../assets/images/home_icon.png'
import targetIcon from '../../../assets/images/target_icon.png'
import user_icon from '../../../assets/images/user_icon.png'
import history_icon from '../../../assets/images/history_icon.png'
import spaces_icon from '../../../assets/images/spaces_icon.png'
import biblio_icon from '../../../assets/images/biblio_icon.png'
import numismatics_icon from '../../../assets/images/numismatics_icon.png'
import arcIcon from '../../../assets/images/arch_icon.png'
import geo_icon from '../../../assets/images/geo_icon.png'
import user_edit from '../../../assets/images/user_edit.png'

const items = [
    {name: 'Dashboard', path: '/', icon: <img style={{width: '16px', height: '16px'}} src={homeIcon} />},
    {
        name: 'Orientamento',
        path: '/orientation?id=1',
        icon: <img style={{width: '16px', height: '16px'}} src={targetIcon} />
    },
    {
        name: 'Personaggi',
        path: '/characters?id=2',
        icon: <img style={{width: '16px', height: '16px'}} src={user_icon} />
    },
    {name: 'Storia', path: '/history?id=3', icon: <img style={{width: '16px', height: '16px'}} src={history_icon} />},
    {name: 'Spazi', path: '/spaces?id=4', icon: <img style={{width: '16px', height: '16px'}} src={spaces_icon} />},
    {
        name: 'Fondi bibliografici',
        path: '/bibliographic-funds?id=5',
        icon: <img style={{width: '16px', height: '16px'}} src={biblio_icon} />
    },
    {
        name: 'Numismatica',
        path: '/numismatics?id=6',
        icon: <img style={{width: '16px', height: '16px'}} src={numismatics_icon} />
    },
    {
        name: 'Archeologia',
        path: '/archeology?id=7',
        icon: <img style={{width: '14px', height: '16px'}} src={arcIcon} />
    },
    {
        name: 'Strumenti scientifici',
        path: '/scientific-instruments?id=8',
        icon: <img style={{width: '16px', height: '16px'}} src={geo_icon} />
    }
]

export type MenuItem = {
    name: string
    path: string
}

export const Navbar = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const user = useAuthStore(state => state.user)
    const [isLoading, setIsLoadaing] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const handleLogout = () => {
        setIsLoadaing(true)
        logout()
    }

    return (
        <NavbarWrapper>
            <NavbarGrid>
                <img src={Logo} alt={'logo'} height={57} onClick={() => navigate('/')} />
                <MenuItems>
                    {items.map(({name, path, icon}) => (
                        <NavLink path={path} name={name} key={name} icon={icon} />
                    ))}
                    {user?.role === RoleType.admin && (
                        <NavLink
                            path={'/users'}
                            name={'Utenti'}
                            icon={<img style={{width: '16px', height: '16px'}} src={user_edit} />}
                        />
                    )}
                </MenuItems>

                <AvatarContainer>
                    <AvatarNameContainer onClick={() => navigate('/profile')}>
                        <span className="name">
                            {user?.firstName} {user?.lastName}
                        </span>
                        <span className="email">{user?.email}</span>
                    </AvatarNameContainer>
                    <LogoutIcon
                        size={24}
                        onClick={() => setShowModal(true)}
                        style={{cursor: 'pointer', marginTop: '8px'}}
                    />
                </AvatarContainer>
            </NavbarGrid>

            {showModal && (
                <LogoutModal
                    onDelete={handleLogout}
                    onClose={() => setShowModal(false)}
                    title={t('modal:logout:title')}
                    paragraph={t('modal:logout:description')}
                    isLoading={isLoading}
                />
            )}
        </NavbarWrapper>
    )
}
