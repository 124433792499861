import {CSSProperties, forwardRef, InputHTMLAttributes, ReactNode, useEffect, useState} from 'react'
import {DefaultNamespace} from 'i18next'
import {
    StyledInputWrapper,
    StyledInputFile,
    StyledImageContainer,
    StyledImageWrapper
} from '@components/commons/input-file/style.ts'
import {Accept, useDropzone} from 'react-dropzone'
import {useTranslation} from 'react-i18next'
import {megabytesToBytes} from '@utilities/helpers.ts'
import {Label} from '@components/ui/label/Label.tsx'
import {InputStatusIcon} from '@components/ui/input-status-icon/InputStatusIcon.tsx'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText.tsx'
import ImgPlaceholder from '@assets/images/file_placeholder.svg'
import FileCloseIcon from '@assets/images/file_close_icon.svg'
import toast from 'react-hot-toast'

export interface InputFileProps extends InputHTMLAttributes<HTMLInputElement> {
    acceptedFiles: Accept
    className?: string
    label?: string | DefaultNamespace
    maxFiles?: number
    maxSizeInMB: number
    name?: string
    onAcceptFiles?: (file: File) => void
    onRemoveFile?: () => void
    placeholder?: string
    typeIcon?: ReactNode
    touched?: boolean
    errorMessage?: string | DefaultNamespace
    helpText?: string | DefaultNamespace
    width?: CSSProperties['width']
    imagePath?: string | null
}

export const InputFile = forwardRef<HTMLInputElement | null, InputFileProps>(
    (
        {
            acceptedFiles,
            className,
            errorMessage,
            helpText,
            label,
            maxFiles,
            maxSizeInMB,
            name,
            onAcceptFiles,
            onRemoveFile,
            touched = false,
            width = '100%',
            imagePath
        },
        ref
    ) => {
        const {t} = useTranslation()
        const [previewUrl, setPreviewUrl] = useState<string | null>(null)
        const [fileError, setFileError] = useState<string | null>(null)

        useEffect(() => {
            if (imagePath && typeof imagePath === 'string') {
                setPreviewUrl(imagePath)
            }
        }, [imagePath])

        const dropzoneState = useDropzone({
            accept: acceptedFiles,
            onDrop: acceptedFiles => {
                const [file] = acceptedFiles
                if (file) {
                    const objectUrl = URL.createObjectURL(file)
                    setPreviewUrl(objectUrl)
                    setFileError(null)
                    onAcceptFiles?.(file)
                }
            },
            onDropRejected: rejectedFiles => {
                const file = rejectedFiles[0]
                if (file.errors[0].code === 'file-too-large') {
                    toast.error(`Il file è più grande di ${maxSizeInMB} MB`)
                } else if (file.errors[0].code === 'file-invalid-type') {
                    toast.error('Il tipo del file non è compatibile')
                } else {
                    toast.error('Upload Fallito')
                }
            },
            maxFiles,
            maxSize: megabytesToBytes(maxSizeInMB)
        })

        const onRemoveImage = () => {
            if (previewUrl && !imagePath) {
                URL.revokeObjectURL(previewUrl)
            }
            setPreviewUrl(null)
            setFileError(null)
            onRemoveFile?.()
        }

        return (
            <StyledInputFile className={className} $width={width} direction="column" gap={1.5}>
                {label && <Label htmlFor={name}>{label}</Label>}
                {previewUrl ? (
                    <StyledImageWrapper>
                        <StyledImageContainer>
                            <img src={previewUrl} alt="Uploaded Image" />
                            <button
                                type="button"
                                onClick={onRemoveImage}
                                style={{cursor: 'pointer'}}
                                className="remove_cta"
                            >
                                <img src={FileCloseIcon} alt="Remove Image" width={21} height={21} />
                            </button>
                        </StyledImageContainer>
                        <div className="description_container">
                            <p className="file-name">{dropzoneState.acceptedFiles[0]?.name}</p>
                        </div>
                    </StyledImageWrapper>
                ) : (
                    <div className={`${className} presentation`} {...dropzoneState.getRootProps()}>
                        <input {...dropzoneState.getInputProps()} ref={ref} />
                        <StyledInputWrapper touched={touched} hasError={!!errorMessage || !!fileError}>
                            <img src={ImgPlaceholder} alt="Upload Image" width={96} height={96} />
                            <div className="description_container">
                                <p>{t('upload:title')}</p>
                                <p>{`${t('upload:size')} ${maxSizeInMB}mb.`}</p>
                                <p>{t('upload:file')}</p>
                                <InputStatusIcon touched={touched} hasError={!!errorMessage || !!fileError} />
                            </div>
                        </StyledInputWrapper>
                    </div>
                )}
                {fileError && <p className="error-text">{fileError}</p>}
                <InputHelpText error={errorMessage} helpText={helpText} />
            </StyledInputFile>
        )
    }
)

InputFile.displayName = 'InputFile'
