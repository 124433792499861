import axios from '@/axiosInstance.ts'
import {z} from 'zod'
import {
    ChangeRequest,
    ChangeRequestSchema,
    ForgotRequest,
    LoginRequest,
    LoginResponse,
    LoginResponseSchema
} from './LoginSchema.ts'

/**
 * This function is used to validate the code received during the sso process
 */
const SSOAuthorizationSchema = z.object({
    access_token: z.string(),
    refresh_token: z.string(),
    user_id: z.coerce.number().int()
})
export const HttpSSOResponse = z.object({
    oauth2_authorization: SSOAuthorizationSchema,
    metadata: z.array(z.string())
})
export type HttpSSOResponse = z.infer<typeof HttpSSOResponse>
export const httpSSOLogin = (code: string) => {
    return axios.post(`/host-services/login`, {code})
}

//Login

export const userLogin = async (payload: LoginRequest): Promise<LoginResponse> => {
    const response = await axios.post(`/auth/login/`, {
        email: payload.email,
        password: payload.password
    })
    return LoginResponseSchema.parse(response.data)
}

export const userRecoveryPassword = async (payload: ForgotRequest): Promise<{message: string}> => {
    const response = await axios.post(`/password-recovery`, {
        email: payload.email
    })
    return {
        message: response.data.message
    }
}

export const userChangePassword = async (payload: ChangeRequest, token: string) => {
    const response = await axios.post(`/password-recovery/reset`, {
        token: token,
        password: payload.password,
        passwordConfirmation: payload.passwordConfirmation
    })
    return ChangeRequestSchema.parse(response.data)
}

export const adminChangePassword = async (payload: {password: string; passwordConfirmation: string}) => {
    const response = await axios.post(`/auth/change-password/`, {
        password: payload.password,
        passwordConfirmation: payload.passwordConfirmation
    })

    return response.data
}

/**
 * Simple Logout function
 */
export const httpLogout = () => {
    return axios.post(`/auth/logout/`)
}

/**
 * This function Retrieves user data
 */
export const httpUserMe = () => {
    return axios.get(`/host-services/hosts/me`)
}
